import { useState } from 'react';
import { Auth } from 'aws-amplify';

export const useVerifyUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const verifyUser = async ({email, password, confirmation_code}) => {
    setError(null);
    setIsLoading(true);
    try {
      
      await Auth.confirmSignUp(email,confirmation_code);
      await Auth.signIn(email,password);

      setSuccess(true);
      setIsLoading(false);
      
    } catch (error) {

      console.error('Error verifying user:', error);
      setError(error.message || "An unexpected error occurred");

      setIsLoading(false);
      throw error;
    }
  }

  return { verifyUser, isLoading, error, success}
}
