import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControlLabel, Radio, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';

export const SingleSelectTile = (props) => {
  const {
    id,
    value,
    label,
    checked,
    imageUrl,
    selectedUrl,
    loading,
    onToggle,
  } = props;

  const theme = useTheme();

  const handleToggle = () => {
    onToggle(id);
  };

  const handleKeyDown = (event) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      handleToggle();
    }
  };

  return (
    <FormControlLabel
      tabIndex={0} // Ensure focusability
      onKeyDown={handleKeyDown} // Attach keydown handler
      value={value}
      key={id}
      control={
        <Radio sx={visuallyHidden} />
      }
      disabled={loading}
      sx={{ margin: '20px 10px', position: 'relative' }}
      checked={checked}
      onChange={handleToggle}
      label={
        <>
          <Box
            component="img"
            src={checked ? selectedUrl : imageUrl}
            alt={label}
            sx={{
              maxWidth: '100%',
              borderRadius: '5px',
              border: '1px solid' + theme.palette.common.dark,
              background: 'rgba(38, 44, 42, 0.12)',
              width: '200px',
              height: '200px',
              [theme.breakpoints.down('sm')]: {
                width: '125px',
                height: '125px'
              },
            }}
          />

          <Typography
            component="div"
            sx={{
              textAlign: 'center',
              color: theme.palette.common.dark,
              fontWeight: 'bold',
            }}
          >
            {label}
          </Typography>
        </>
      }
    />
  );
};

SingleSelectTile.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  checked: PropTypes.bool,
  imageUrl: PropTypes.string,
  selectedUrl: PropTypes.string,
  onToggle: PropTypes.func,
};

export default SingleSelectTile;
