import { Auth } from "aws-amplify";
import { useCallback } from "react";

export const useUpdateCurrentUserProfile = () => {
  
  const updateUserProfile = useCallback(async  (attributes) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, attributes);
      return { succeess: true, result};
    } catch (error) {
      return { success: false, error}
    }
   
  }, []);

  return { updateUserProfile };
};

