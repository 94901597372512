export const intendUseOptions = [
  {
    id: '1',
    label: 'Track my daily actions to form greener behaviours',
  },
  {
    id: '2',
    label:
      'Track impact from bigger one off actions and bigger events (tree plantings, clean ups, etc.)',
  },
  {
    id: '3',
    label: 'Grow my knowledge and understanding of climate',
  },
];
