import en from './en';
import fr from './fr';
import es from './es';

const localization = {
  en,
  fr,
  es,
};

export default localization;
