const DASHBOARD_VERSION = {
  SIMPLE: 'simple',
  ROBUST: 'robust',
};

export const getDashboardVersion = () =>
  process.env.REACT_APP_DASHBOARD_VERSION || DASHBOARD_VERSION.SIMPLE;

export const isSimpleDashboardVersion = () =>
  getDashboardVersion() === DASHBOARD_VERSION.SIMPLE;
