import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { SIGNUP_NAMES, SIGNUP_PATHS } from '../../../constants/signup-paths';
import useTranslation from '../../../components/customHooks/translations';
import { useSignupAvatarSelect } from '../../../hooks/use-signup-avatar-select.hook';
import { SingleSelectTileGroup } from '../../../components/SingleSelectTile/SingleSelectTileGroup';
import { LoadingSpinner } from '../LoadingSpinner';

export const AvatarSelect = () => {
  const { signup } = useTranslation();
  const navigate = useNavigate();
  const { avatarOptions, avatar, setAvatar, sendAvatarRequest } =
    useSignupAvatarSelect();
  const [loading, setLoading] = React.useState(false);

  const onSubmitForm = async (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);

      try {
        await sendAvatarRequest();
        setLoading(false);
        navigate(SIGNUP_PATHS.AVATAR_REVIEW);
      } catch (e) {
        setLoading(false);
        console.log('Error: ', e);
      }
    }
  };

  return (
    <Box data-testid="avatar-select">
      <Typography variant="h1">{signup.avatarSelectHeading}</Typography>
      <Typography
        variant="p"
        component="p"
        m="1em 0 2em"
        id="avatar-select-label"
      >
        {signup.avatarSelectSubHeading}
      </Typography>

      <Box
        id={`form-${SIGNUP_NAMES.AVATAR_SELECT}`}
        component="form"
        onSubmit={onSubmitForm}
      >
        <SingleSelectTileGroup
          selectionOptions={avatarOptions}
          selection={avatar}
          setSelection={setAvatar}
          ariaLabel="avatar-select-label"
          name="avatar"
        />
      </Box>

      <LoadingSpinner loading={loading} />
    </Box>
  );
};
