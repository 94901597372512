import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { BaseComponent } from '../../../prop-types/component';
import useTranslation from '../../../components/customHooks/translations';
import { useAvatar } from '../../../hooks/use-get-avatar.hook';

export const UsageInstruct = () => {
  const { signup } = useTranslation();
  const { avatar, isLoading } = useAvatar();

  return (
    <Box data-testid="usage-instruct">
      {!isLoading && (
        <Box
          component="img"
          alt=""
          src={avatar.phone || avatar.imageUrl}
          sx={{ mb: '1em', width: '10%', height: '10%', minWidth: '8em' }}
        />
      )}
      <Typography variant="h1">{signup.usageInstructHeading}</Typography>
      <Typography
        variant="p"
        component="p"
        sx={{
          lineHeight: '1.5',
          maxWidth: '600px',
          margin: '0 auto',
          paddingTop: '1.25em',
        }}
      >
        {signup.usageInstructSubheading}
      </Typography>
    </Box>
  );
};

UsageInstruct.propTypes = {
  ...BaseComponent,
};
