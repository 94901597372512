import * as React from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { LogActionContainer } from '../../../views/LogActionContainer/LogActionContainer';
import { PAGE_PATHS } from '../../../constants/page-paths';
import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

import useTranslation from '../../../components/customHooks/translations';
import { LogoInstagram } from '../../../components/LogoInstagram';
import { LogoTikTok } from '../../../components/LogoTikTok';
import { useGetSingleAction } from '../../../hooks/use-get-single-action';
import theme from '../../../themes';
import { useAvatar } from '../../../hooks/use-get-avatar.hook';
import { formatCo2Saved } from '../../../utils/format-co2-saved';
import { copyToClipboard } from '../../../utils/copy-to-clipboard';
import { useGetUserAction } from '../../../hooks/use-get-user-action';
import { avatarOptions } from '../../../constants/avatar-options';

const socialLinks = [
  {
    key: 'instagram',
    link: 'https://www.instagram.com/',
    label: 'shareOnInstagram',
    icon: LogoInstagram,
  },
  {
    key: 'tiktok',
    link: 'https://www.tiktok.com/',
    label: 'shareOnTikTok',
    icon: LogoTikTok,
  },
];
export const ShareSocial = () => {
  const translation = useTranslation();
  const { userAction } = useGetUserAction();
  const { actionStyles } = useGetSingleAction();
  const { avatar, isLoading } = useAvatar();
  const { userActionValues } = useOutletContext();
  const { action } = useGetSingleAction();

  const [imageUrl, setImageUrl] = useState();
  const defaultAvatarImage = avatarOptions[0].imageUrl;

  useEffect(() => {
    if(userActionValues?.image){
      setImageUrl(URL.createObjectURL(userActionValues?.image))
    } else if(!isLoading && action){

      const actionAvatar = avatar.actions.find(x => x.id === action.id);
      const avatarIcon = actionAvatar?.icon ? 
        actionAvatar.icon : 
        avatar ? 
        avatar.imageUrl :
        defaultAvatarImage;
      setImageUrl(avatarIcon);
    }
  }, [userActionValues?.image, action, avatar, defaultAvatarImage, isLoading]);

  useEffect(() => {

  }, [imageUrl])
  const [copied, setCopied] = useState(false);
  const [isCardBlurred, setIsCardBlurred] = useState(false);
  

  const onShare = (e) => {
    e.preventDefault();
    toggleCardBlur();
    onCopyAndShare();
  };

  const toggleCardBlur = () => {
    setIsCardBlurred((prevState) => !prevState);
  };

  const getPointsEarned = () =>
    userActionValues?.pointsEarned > userAction?.pointsEarned
      ? userActionValues?.pointsEarned
      : userAction?.pointsEarned;

  const shareSummary =
    userAction?.co2Saved > 0
      ? translation.formatString(
          translation.logActionShareSummarySimple,
          formatCo2Saved(userAction?.co2Saved)
        )
      : translation.formatString(translation['logActionPage'].dareYouToMatch, getPointsEarned() || 0);

  const shareHashtag = translation.commit2ActHashtag;


  const onCopyAndShare = async () => {
    const textToCopy = `${shareSummary} ${shareHashtag}`;
  
    try {
      await copyToClipboard(textToCopy);
      setCopied(true);
    } catch (e) {
      console.log('Could not copy text: ', e);
      setCopied(false);
    }
  };

  return (
    <>
      <LogActionContainer
        step={5}
        buttons={
          <>
            <Button
              component={Link}
              to={PAGE_PATHS.DASHBOARD}
              variant="outlined"
              sx={{
                display: 'block',
                marginBottom: '1rem',
                margin: '0 auto',
                border: '1px solid white',
              }}
            >
              {translation.logActionButtonAllDone}
            </Button>
          </>
        }
        sx={{ textAlign: 'center' }}
      >
        <Box>
          <Typography variant="h1" color={actionStyles}>
            {translation.logActionStep6}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Card
              sx={{
                marginY: '3.75rem',
                borderRadius: '15px',
                boxShadow:
                  '90px 374px 108px 0px rgba(0, 0, 0, 0.00), 58px 240px 99px 0px rgba(0, 0, 0, 0.01), 32px 135px 83px 0px rgba(0, 0, 0, 0.05), 14px 60px 62px 0px rgba(0, 0, 0, 0.09), 4px 15px 34px 0px rgba(0, 0, 0, 0.10)',
                position: 'relative',
                maxWidth: '40rem',
              }}
            >
              <CardActionArea
                onClick={onShare}
                sx={{ filter: isCardBlurred ? 'blur(7.5px)' : '' }}
              >
                {!isLoading && (
                  <Box
                    sx={{
                      backgroundColor: '#303839',
                      height: 300,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '15px',
                    }}
                  >
                    <CardMedia
                      component="img"
                     
                      src={imageUrl}
                      alt=""
                      sx={{
                        display: isLoading ? 'none' : 'block',
                      }}
                    />
                  </Box>
                )}
                <CardContent
                  style={{
                    backgroundColor: theme.palette.common.white,
                    position: 'relative',
                  }}
                >
                  <Typography
                    variant="p"
                    display="block"
                    textAlign="center"
                    color={theme.palette.common.dark}
                    sx={{ paddingX: '2rem' }}
                  >
                    {shareSummary}
                  </Typography>
                  <Typography
                    variant="p"
                    display="block"
                    fontWeight="bold"
                    textAlign="center"
                    color={theme.palette.common.dark}
                    paddingTop="0.625rem"
                  >
                    {shareHashtag}
                  </Typography>
                </CardContent>
              </CardActionArea>
              {isCardBlurred && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 10,
                  }}
                >
                  {socialLinks.map((link) => (
                    <Button
                      key={link.key}
                      component="a"
                      href={link.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="large"
                      sx={{
                        marginRight: '1rem',
                        backgroundColor: '#131719',
                        borderRadius: '0.625rem',
                        padding: '1rem',
                      }}
                    >
                      <link.icon />
                    </Button>
                  ))}
                </Box>
              )}
            </Card>
          </Box>
        </Box>
        <Button
          onClick={onShare}
          variant="contained"
          sx={{
            display: 'block',
            margin: '0 auto',
            fontSize: '1.75rem',
            minWidth: '8em',
          }}
        >
          {copied ? translation.copyButtonCopied : translation.share}
        </Button>
      </LogActionContainer>
    </>
  );
};
