import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useDashboardData } from './DashboardProvider';
import useTranslation from '../customHooks/translations';


const StatsBox = () => {
  const { statsObject } = useDashboardData();
  const theme = useTheme();
  const {dashboardPage: translation} = useTranslation();
  /** functions for formatNumber */
  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + ' tonnes';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(0) + ' kg';
    } else {
      return num.toFixed(0) + ' g';
    }
  }

  function formatPoints(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + ' M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(0) + ' K';
    } else {
      return num.toLocaleString();
    }
  }

  return (
    <Box sx={{
      display: 'grid',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridAutoRows: '1fr',
      },
      gridTemplateColumns: 'repeat(1, 1fr)',

      gridColumnGap: '20px',
      gridRowGap: '20px',
      alignItems:'center',
      textAlign: 'center',
      '& div:first-of-type': {
        gridArea: `1 / 1 / 2 / 3`,
      },
      '& div' : {
        height: '100%',
        boxSizing: 'border-box',
      },
      '& h3' : {
        color: '#69D6BA',
        fontSize: '30px',
        fontWeight:'900'
      }
    }}>
      <Paper sx={{
        borderRadius: '20px',
        background: 'linear-gradient(180deg, #00E8FF 0%, #26AAB7 78.82%)',
        padding: '2em 1em',
        textAlign: 'center',
        color: theme.palette.common.dark,
        lineHeight: '1.2em',
      }}>
        <Typography sx={{fontWeight: '900', paddingBottom: '15px', fontSize: '1.67em', color: theme.palette.common.dark}}>{translation.totalPointsColon} {formatPoints(statsObject?.total_points)}</Typography>
 
          <Typography variant="body">{translation.thisWeek}< br/>
          <TrendingUpIcon sx={{color: theme.palette.secondary.main, marginBottom: '-5px' }}/> <strong>{formatPoints(statsObject?.weekly_points)}</strong>
        </Typography>
      </Paper>
      <Paper sx={{padding: '1rem', background: '#303839', boxShadow: '0px 4px 24px rgba(105, 214, 186, 0.08) inset', borderRadius: 4, border: '1px #6FDDDD solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1, display: 'inline-flex'}}>
        <Typography variant="h3">{statsObject?.number_of_actions}</Typography>
        {translation.actionsTaken}
      </Paper> 
      <Paper sx={{padding: '1rem', background: '#303839', boxShadow: '0px 4px 24px rgba(105, 214, 186, 0.08) inset', borderRadius: 4, border: '1px #6FDDDD solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1, display: 'inline-flex'}}>
        <Typography variant="h3">{formatNumber(statsObject?.weekly_co2)}</Typography>
        <span>{translation.weeklyCO2}</span>
      </Paper>   
      <Paper sx={{padding: '1rem', background: '#303839', boxShadow: '0px 4px 24px rgba(105, 214, 186, 0.08) inset', borderRadius: 4, border: '1px #6FDDDD solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1, display: 'inline-flex'}}>
        <Typography variant="h3">{formatNumber(statsObject?.total_co2)}</Typography>
        <span>{translation.totalCO2Saved}</span>
      </Paper>
      <Paper sx={{padding: '1rem', background: '#303839', boxShadow: '0px 4px 24px rgba(105, 214, 186, 0.08) inset', borderRadius: 4, border: '1px #6FDDDD solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1, display: 'inline-flex'}}>
        <Typography variant="h3">{statsObject?.streak}</Typography>
        {translation.currentStreak}
      </Paper>  
    </Box>
    
  );
};

export default StatsBox;