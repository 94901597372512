import React, { createContext, useContext } from 'react';
import { useCurrentUserDashboard } from '../../hooks/use-current-user-dashboard';

const DashboardDataContext = createContext();

export const useDashboardData = () => useContext(DashboardDataContext);

export const DashboardDataProvider = ({ children }) => {
  const DashboardData = useCurrentUserDashboard();

  return (
    <DashboardDataContext.Provider value={DashboardData}>
      {children}
    </DashboardDataContext.Provider>
  );
};