import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';

export const MultiSelectTile = (props) => {
  const {
    id,
    label,
    value,
    checked,
    imageUrl,
    selectedUrl,
    loading,
    onToggle,
  } = props;

  const theme = useTheme();

  const handleToggle = () => {
    onToggle(id);
  };

  const handleKeyDown = (event) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      handleToggle();
    }
  };

  return (
    <FormControlLabel
      key={id}
      value={value}
      control={<Checkbox sx={visuallyHidden} />}
      disabled={loading}
      sx={{ padding: '0.625rem 0.313rem' }}
      checked={checked}
      label={
        <Box
          alt={label}
          sx={{
            maxWidth: '100%',
            backgroundImage: `url('${checked ? selectedUrl : imageUrl}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '11.56rem',
            height: '12.93rem',
            borderRadius: '8px',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
              width: '8.1rem',
              height: '9.1rem',
            },
          }}
          onClick={handleToggle}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              background:
                'linear-gradient(180deg, rgba(48, 56, 57, 0.00) 0.11%, #303839 81.81%)',
              borderRadius: '5px',
            }}
          >
            <Typography
              component="div"
              sx={{
                color: `${theme.palette.common.white} !important`,
                fontWeight: 'bold',
                paddingBottom: '16px',
                [theme.breakpoints.down('sm')]: {
                  paddingX: '4px',
                },
              }}
            >
              {label}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};

MultiSelectTile.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  imageUrl: PropTypes.string,
  selectedUrl: PropTypes.string,
  onToggle: PropTypes.func,
};
