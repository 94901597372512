import React, { useState } from 'react';
import { FormControl, RadioGroup as Group } from '@mui/material';
import PropTypes from 'prop-types';
import { Radio } from './Radio';

export const RadioGroup = ({ radios, variant, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleRadioChange = (value) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <FormControl>
      <Group style={{ gap: '0.8em' }}>
        {radios.map((radio) => (
          <Radio
            key={radio.id}
            variant={variant}
            checked={radio.value === selectedValue}
            value={radio.value}
            title={radio.title}
            onChange={handleRadioChange}
            className={radio.value === selectedValue ? 'checked' : ''}
          />
        ))}
      </Group>
    </FormControl>
  );
};

RadioGroup.propTypes = {
  variant: PropTypes.string,
  radios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
};
