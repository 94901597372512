import React from 'react';
import { Box, Typography } from '@mui/material';
import { useDashboardData } from './DashboardProvider';

const Greeting = () => {
  const { greetingText } = useDashboardData();

  return <Box><Typography variant="h1">{greetingText}</Typography></Box>;
};

export default Greeting;