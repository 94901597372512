import { LOG_ACTION_PATHS } from './log-action-paths';

export const LOG_ACTION_ROUTES = [
  {
    step: 1,
    path: LOG_ACTION_PATHS.SELECT_TYPE,
    name: 'SelectType',
  },
  {
    step: 2,
    path: LOG_ACTION_PATHS.ENTER_DETAILS,
    name: 'EnterDetails',
  },
  {
    step: 3,
    path: LOG_ACTION_PATHS.CREATE_ACTION,
    name: 'CreateAction',
  },
  {
    step: 4,
    path: LOG_ACTION_PATHS.ACTION_SAVED,
    name: 'ActionSaved',
  },
  {
    step: 5,
    path: LOG_ACTION_PATHS.BONUS_QUIZ,
    name: 'BonusQuiz',
  },
  {
    step: 6,
    path: LOG_ACTION_PATHS.SHARE_SOCIAL,
    name: 'ShareSocial',
  },
];
