import { useState, useEffect } from 'react';
import { useGetCurrentUserProfile } from './use-get-current-user-profile.hook';
import { avatarOptions } from '../constants/avatar-options';

const defaultAvatar = avatarOptions[0];

export const useAvatar = () => {
  const [avatar, setAvatar] = useState(defaultAvatar);
  const [isLoading, setIsLoading] = useState(false)
  const { userProfile } = useGetCurrentUserProfile();

  useEffect(() => {

    const fetchAvatar = () => {
      setIsLoading(true);
      const avatarId = userProfile['avatar']
      const avatarObject = avatarOptions.find(option => option.id === avatarId);
      setAvatar(avatarObject);
      setIsLoading(false);
    };

    if (userProfile && userProfile['avatar']) {
      fetchAvatar();
    }
  }, [userProfile]);

  return { avatar, isLoading };
};