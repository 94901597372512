import { SelectedImage } from "./action-image-links";

export const actionOptions = [
  {
    
    id: "1",
    label: "Plant based eating",
    imageUrl: "../../assets/images/actions/plantbased.jpg",
    imageAlt: "Plant based eating",
    selectedUrl: SelectedImage
  },
  {
    id: "2",
    label: "Travel by bicycle",
    imageUrl: "../../assets/images/actions/bike2.jpg",
    imageAlt: "Travel by bicycle",
    selectedUrl: SelectedImage
  },
  {
    id: "3",
    label: "Travel by public transport",
    imageUrl: "../../assets/images/actions/publictransit.jpg",
    imageAlt: "Travel by public transport",
    selectedUrl: SelectedImage
  },
  {
    id: "4",
    label: "Walk",
    imageUrl: "../../assets/images/actions/walk.jpg",
    imageAlt: "Walk",
    selectedUrl: SelectedImage
  },
  {
    id: "5",
    label: "Reuse water bottle",
    imageUrl: "../../assets/images/actions/reusablebottle.jpg",
    imageAlt: "Reuse water bottle",
    selectedUrl: SelectedImage
  },
  {
    id: "6",
    label: "Reuse shopping bag",
    imageUrl: "../../assets/images/actions/reusablebag.jpg",
    imageAlt: "Reuse shopping bag",
    selectedUrl: SelectedImage
  },
  {
    id: "7",
    label: "Plant Trees",
    imageUrl: "../../assets/images/actions/planttree.jpg",
    imageAlt: "Plant Trees",
    selectedUrl: SelectedImage
  },
  {
    id: "8",
    label: "Tend to a garden",
    imageUrl: "../../assets/images/actions/garden.jpg",
    imageAlt: "Tend to a garden",
    selectedUrl: SelectedImage
  },
  {
    id: "9",
    label: "Repair jeans",
    imageUrl: "../../assets/images/actions/repair-jeans.jpg",
    imageAlt: "Repair jeans",
    selectedUrl: SelectedImage
  },
  {
    id: "10",
    label: "Repair a T-shirt",
    imageUrl: "../../assets/images/actions/mend-tshirt.jpg",
    imageAlt: "Repair a T-shirt",
    selectedUrl: SelectedImage
  },
  {
    id: "11",
    label: "Wash laundry in cold water",
    imageUrl: "../../assets/images/actions/Laundry.jpg",
    imageAlt: "Wash laundry in cold water",
    selectedUrl: SelectedImage
  }
];


