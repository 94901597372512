import { Box } from '@mui/material';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

const defaultUserActionValues = {
  actionItems: [],
  date: null,
  image: null,
  userActionId: null,
  pointsEarned: 0,
};

const defaultActionQuiz = null;

export const LogActionRoutes = () => {
  const [userActionValues, setUserActionValues] = React.useState(
    defaultUserActionValues
  );
  const [actionQuiz, setActionQuiz] = React.useState(defaultActionQuiz);

  const unsetUserActionValues = () => {
    setUserActionValues(defaultUserActionValues);
    setActionQuiz(defaultActionQuiz);
  };

  return (
    <Box
      className="log-action-routes"
      sx={{ width: '100%', minHeight: '100%' }}
    >
      <Outlet
        context={{
          actionQuiz,
          setActionQuiz,
          userActionValues,
          setUserActionValues,
          unsetUserActionValues,
        }}
      />
    </Box>
  );
};
