import React from 'react';
import { FormControl, RadioGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { SingleSelectTile } from './SingleSelectTile';

export const SingleSelectTileGroup = ({
  selectionOptions,
  selection = '',
  setSelection,
  ariaLabel,
  name,
}) => {
  const handleToggle = (id) => {
    setSelection(id);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-label={ariaLabel}
        value={selection}
        onChange={(event) => handleToggle(event.target.value)}
        sx={{ justifyContent: 'center' }}
        name={name}
        row
      >
        {selectionOptions.map((option) => (
          <SingleSelectTile
            id={option.id}
            value={option.id}
            key={option.id}
            label={option.label}
            checked={selection === option.id}
            loading={option.loading}
            imageUrl={option.imageUrl}
            selectedUrl={option.selectedUrl}
            onToggle={() => handleToggle(option.id)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

SingleSelectTileGroup.propTypes = {
  selectionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      imageUrl: PropTypes.string,
      selectedUrl: PropTypes.string,
      loading: PropTypes.bool,
    })
  ),
  selection: PropTypes.string,
  setSelection: PropTypes.func,
  name: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default SingleSelectTileGroup;
