import * as React from 'react';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { LogActionContainer } from '../../../views/LogActionContainer/LogActionContainer';
import { Button } from '../../../components/Button/Button';
import { getLogActionPath } from '../../../constants/log-action-paths';
import { useGetActionQuiz } from '../../../hooks/use-get-action-quiz.hook';
import { useGetSingleAction } from '../../../hooks/use-get-single-action';
import ActionFactImage from '../../../components/logAction/ActionFactImage';
import { useGetUserAction } from '../../../hooks/use-get-user-action';
import theme from '../../../themes';
import useTranslation from '../../../components/customHooks/translations';

export const CreateAction = () => {
  const { actionId } = useParams();
  const { actionStyles } = useGetSingleAction();
  const { userActionValues } = useOutletContext();
  const {
    actionQuiz: quiz,
    isEmpty: hasNoQuiz,
    loading: loadingQuiz,
  } = useGetActionQuiz();

  const { loading } = useGetUserAction();
  const translation = useTranslation();

  return (
    <LogActionContainer
      step={2}
      buttons={
        <Button
          component={Link}
          to={getLogActionPath(
            'ACTION_SAVED',
            actionId,
            userActionValues?.userActionId
          )}
          disabled={loading || loadingQuiz}
          variant="contained"
        >
          {translation.continue}
        </Button>
      }
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          alignItems: 'center',
          justifyItems: 'center',
          gap: '3rem',
          '& > figure': {
            flex: '0 0',
            '& > figcaption': {
              backgroundColor: 'rgba(0,0,0,0.5) !important',
              padding: '0.1em 0.35em',
              borderRadius: '0.2em',
            },
          },
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
          }}
        >
          <Typography variant="p" component="p" sx={{ color: actionStyles?.color }}>
            {translation['logActionPage'].actionValidation}
          </Typography>
          <Typography variant="h1" mt="2rem" sx={{ color: actionStyles?.color }}>
            {hasNoQuiz ? translation.logActionStep3 : translation['logActionPage'].question}
          </Typography>
          <Typography variant="p" component="p" mt="2rem">
            {hasNoQuiz ? translation['logActionPage'].actionValidation : quiz?.fact}
          </Typography>
        </Box>
        <ActionFactImage />
      </Box>
    </LogActionContainer>
  );
};
