import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, Typography, TextField } from '@mui/material';

export const Select = ({ label, id, ...props }) => {
  const inputLabelProps = props.required ? { required: true } : {};

  return (
    <FormControl fullWidth sx={{ textAlign: 'left' }}>
      <FormLabel htmlFor={id}>
        <Typography
          sx={{ textAlign: 'left', fontWeight: '600' }}
          variant="body1"
        >
          {label}
          {props.required && '*'}
        </Typography>
      </FormLabel>
      <TextField
        {...props}
        id={id}
        InputLabelProps={{ shrink: false }}
        InputProps={{
          sx: {
            borderRadius: '20px',
            background:
              'linear-gradient(90deg, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.10) 100%)',
            overflow: 'hidden',
            width: '100%',
            color: '#000',
          },
        }}
      />
    </FormControl>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Select;
