import React, { useEffect } from 'react';
import { avatarOptions } from '../constants/avatar-options';
import { useUpdateCurrentUserProfile } from './use-update-current-user-profile.hook';
import { useGetCurrentUserProfile } from './use-get-current-user-profile.hook';

export const defaultAvatar = avatarOptions[0].id;

export const useSignupAvatarSelect = () => {
  const { userProfile } = useGetCurrentUserProfile();
  const { updateUserProfile } = useUpdateCurrentUserProfile();
  const [avatar, setAvatar] = React.useState(defaultAvatar);

  useEffect(() => {
    if (
      userProfile !== undefined 
    ) {
      setAvatar(userProfile['custom:avatar']);
    }
  }, [userProfile]);

  const sendAvatarRequest = async () => await updateUserProfile({'custom:avatar': avatar});

  return {
    avatarOptions,
    avatar,
    setAvatar,
    sendAvatarRequest,
  };
};
