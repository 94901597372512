import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { BaseComponent } from '../../../prop-types/component';
import useTranslation from '../../../components/customHooks/translations';
import { useGetCurrentUserProfile } from '../../../hooks/use-get-current-user-profile.hook';
import { avatarOptions } from '../../../constants/avatar-options';

export const AvatarReview = () => {
  const { signup, formatString } = useTranslation();
  const { userProfile } = useGetCurrentUserProfile();

  const avatar = avatarOptions.find(option => option.id === userProfile?.avatar);

  return (
    <Box data-testid="avatar-review">
      {avatar ? (
        <Box
          component="img"
          src={avatar?.imageUrl}
          alt={avatar?.imageAlt}
          sx={{ maxWidth: '12em', mb: '1em' }}
          data-testid="avatar-image"
        />
      ) : null}
      <Typography variant="h1">{signup.avatarReviewHeading}</Typography>
      {avatar ? (
        <Typography variant="p" component="p" mt="1em">
          {formatString(signup.avatarReviewSubHeading, avatar?.label)}
        </Typography>
      ) : null}
    </Box>
  );
};

AvatarReview.propTypes = {
  ...BaseComponent,
};
