import * as React from 'react';
import { PropTypes } from 'prop-types';
import { Navigate } from 'react-router-dom';
import { PAGE_PATHS } from '../constants/page-paths';

export const PrivateRoute = ({
  children,
  isUserLoggedIn = undefined,
  redirectTo = PAGE_PATHS.LOGIN,
}) => {
  if (isUserLoggedIn === undefined) return null;
  if (isUserLoggedIn === false){
    localStorage.setItem('redirectAfterLogin', window.location.pathname);
    return <Navigate to={redirectTo} />;
  } 
  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
  isUserLoggedIn: PropTypes.bool,
  redirectTo: PropTypes.string,
};
