import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { LogActionContainer } from '../../../views/LogActionContainer/LogActionContainer';
import { Button } from '../../../components/Button/Button';
import { getLogActionPath } from '../../../constants/log-action-paths';
import { useGetActionQuiz } from '../../../hooks/use-get-action-quiz.hook';
import { useGetUserAction } from '../../../hooks/use-get-user-action';
import { DisplayWeight } from '../../../components/DisplayWeight';
import { useGetSingleAction } from '../../../hooks/use-get-single-action';
import { useAvatar } from '../../../hooks/use-get-avatar.hook';
import { useTheme } from '@mui/material/styles';
import useTranslation from '../../../components/customHooks/translations';

export const ActionSaved = () => {
  const { actionId, userActionId } = useParams();
  const { actionStyles, isLoading: isActionLoading } = useGetSingleAction();
  const { isEmpty } = useGetActionQuiz();
  const { userAction, isLoading: isUserActionLoading } = useGetUserAction();
  const { avatar, isLoading: isAvatarLoading } = useAvatar();
  const translation = useTranslation();
  const theme = useTheme();

  // Check if all data is loaded
  const isDataLoaded =
    !isActionLoading && !isUserActionLoading && !isAvatarLoading;

  const isLoading = isActionLoading || isUserActionLoading || isAvatarLoading;

  return (
    <LogActionContainer
      step={3}
      buttons={
        <>
          {!isEmpty && (
            <Button
              component={Link}
              to={getLogActionPath('SHARE_SOCIAL', actionId, userActionId)}
              variant="outlined"
              sx={{
                border: '1px solid white',
                [theme.breakpoints.down('sm')]: { order: '2' },
              }}
            >
              {translation.skip}
            </Button>
          )}
          <Button
            component={Link}
            to={getLogActionPath(
              isEmpty ? 'SHARE_SOCIAL' : 'BONUS_QUIZ',
              actionId,
              userActionId
            )}
            variant="contained"
          >
            {isEmpty ? translation.continue : translation.bonusQuiz}
          </Button>
        </>
      }
      sx={{ marginBottom: '4rem' }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gridGap: '2rem',
          [theme.breakpoints.down('md')]: {
            justifyItems: 'center',
          },
        }}
      >
        {/* Points */}
        <Box
          sx={{
            gridColumn: { sx: '1', md: '1/2' },
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
          }}
        >
          {isDataLoaded &&
            (userAction?.co2Saved !== 0 ? (
              <>
                <Typography variant="h1" sx={{ color: actionStyles?.color }}>
                  + <DisplayWeight weight={userAction?.co2Saved} />
                </Typography>
                <Typography variant="p" component="p" mt="1em">
                  {translation['logActionPage'].cO2Saved}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h1" sx={{ color: actionStyles?.color }}>
                  + {userAction?.pointsEarned}
                </Typography>
                <Typography variant="p" component="p" mt="1em">
                  {translation['logActionPage'].pointsEarned}
                </Typography>
              </>
            ))}
        </Box>

        {/* Picture */}
        {!isLoading && (
          <Box
            component="img"
            alt=""
            src={avatar.animatedUrl || avatar.imageUrl}
            sx={{
              maxWidth: '500px',
              width: '100%',
              gridRow: { md: 'span 2' },
            }}
          />
        )}
        {/* Text */}
        <Box sx={{ gridColumn: { sx: '1', md: '1/2' } }}>
          <Typography
            variant="h2"
            sx={{ color: actionStyles?.color, fontWeight: 'bold' }}
          >
            {translation['logActionPage'].savingThePlanet}
          </Typography>
          <Typography variant="p" component="p" mt="0.5em">
            {translation.formatString(translation['logActionPage'].pointsEarnedForYourAction, userAction?.pointsEarned || 0)}
          </Typography>
          {!isEmpty ? (
            <Typography variant="p" component="p">
              {translation['logActionPage'].takeTheBonusQuiz}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </LogActionContainer>
  );
};
