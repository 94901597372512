import * as React from 'react';
import PropTypes from 'prop-types';
import { BaseComponent } from '../../prop-types/component';
import { Box, CircularProgress, Typography } from '@mui/material';
import useTranslation from '../../components/customHooks/translations';

export const LoadingSpinner = ({
  loading = false,
  children = null,
  ...props
}) => {
  const { signup } = useTranslation();
  return (
    <Box aria-live="polite" {...props}>
      {loading ? (
        <>
          <CircularProgress
            aria-label={signup.loadingText}
            aria-hidden="true"
            sx={{ marginTop: '1rem' }}
          />
          <Typography variant="overline" component="p" mb="-4rem" pl="1em">
            {children ? children : `${signup.loadingText}...`}
          </Typography>
        </>
      ) : null}
    </Box>
  );
};

LoadingSpinner.propTypes = {
  ...BaseComponent,
  loading: PropTypes.bool,
};
