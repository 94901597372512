import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useTranslation from '../customHooks/translations';
import theme from '../../themes';

export const TextInput = ({
  value,
  placeholder,
  label,
  id,
  inputType = 'text',
  sx = {},
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const { textInput } = useTranslation();

  function handleChange() {
    setShowPassword((prev) => !prev);
  }

  return (
    <FormControl fullWidth sx={{ ...sx, textAlign: 'left' }}>
      <FormLabel htmlFor={id}>
        <Typography
          sx={{
            textAlign: 'left',
            fontWeight: '600',
          }}
          variant="body1"
        >
          {label}
          {props.required && (
            <Typography
              aria-hidden="true"
              component="span"
              sx={{ color: 'darkred !important' }}
            >
              *
            </Typography>
          )}
        </Typography>
      </FormLabel>
      <TextField
        {...props}
        id={id}
        placeholder={value === '' ? placeholder : ''}
        type={
          inputType === 'password'
            ? showPassword
              ? 'text'
              : 'password'
            : 'text'
        }
        sx={{
          '& .Mui-error': {
            color: 'darkred !important',
            '&.MuiFormHelperText-root': {
              fontSize: '0.425em',
              lineHeight: '1.35',
              marginTop: '0.5em',
            },
            '& > .MuiOutlinedInput-notchedOutline': {
              borderColor: 'darkred !important',
            },
          },
        }}
        InputLabelProps={{ shrink: false }}
        InputProps={{
          sx: {
            borderRadius: '20px',
            background:
              'linear-gradient(90deg, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.10) 100%)',
            overflow: 'hidden',
            width: '100%',
            color: '#000',
          },
          ...(inputType === 'password' && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={textInput.togglePasswordVisibility}
                  onClick={handleChange}
                  edge="end"
                  style={{ color: theme.palette.common.dark }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }),
        }}
      />
    </FormControl>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  sx: PropTypes.object,
};

export default TextInput;
