import * as React from 'react';
import { Lightbulb } from '@mui/icons-material';


const GradientLightbulb = () => (
  <>
    <svg width={0} height={0}>
      <defs>
        <linearGradient id="gradient">
          <stop offset="0%" stopColor="#33AF99" />
          <stop offset="100%" stopColor="#56C573" />
        </linearGradient>
      </defs>
    </svg>
    <Lightbulb
      sx={{ fill: 'url(#gradient)', marginRight: '0.95rem' }} // Apply the gradient to the path's fill
    />
  </>
);

export default GradientLightbulb;
