// Province names pulled from Government of Canada website in official languages:
// English: https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/prepare-life-canada/provinces-territories.html
// French: https://www.canada.ca/fr/immigration-refugies-citoyennete/services/nouveaux-immigrants/preparer-vie-canada/provinces-territoires.html

export const PROVINCES = {
  en: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
  fr: {
    AB: 'Alberta',
    BC: 'Colombie-Britannique',
    MB: 'Manitoba',
    NB: 'Nouveau-Brunswick',
    NL: 'Terre-Neuve-et-Labrador',
    NS: 'Nouvelle-Écosse',
    NT: 'Territoires du Nord-Ouest',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Île-du-Prince-Édouard',
    QC: 'Québec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
};
