export const customFieldOptions = [
  {
    attributeName: 'ageRange',
    authFieldName: 'custom:ageRange'
  },
  {
    attributeName: 'country',
    authFieldName: 'custom:country'
  },
  {
    attributeName: 'province',
    authFieldName: 'custom:province'
  },
  {
    attributeName: 'avatar',
    authFieldName: 'custom:avatar'
  },
  {
    attributeName: 'familiar',
    authFieldName: 'custom:familiar'
  },
  {
    attributeName: 'intendUse',
    authFieldName: 'custom:intendUse'
  },
  {
    attributeName: 'preferActions',
    authFieldName: 'custom:preferActions'
  },
  {
    attributeName: 'ignoreActions',
    authFieldName: 'custom:ignoreActions'
  },
]
