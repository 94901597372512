import React, { useState } from 'react';
import { Box, Typography, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Delete, CameraAlt } from '@mui/icons-material';
import theme from '../../themes';

import { useActiveStepContext } from '../../hooks/use-active-step-context';
import useTranslation from '../customHooks/translations';
import UploadPhotoTitlePopover from './UploadPhotoTitlePopover';

const Dropbox = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
  border-radius: 1.5rem;
  padding: 1em 2em 2em;
  width: 80%;
  opacity: ${(props) => (props.itemdraggedover ? '0.5' : '1')};
  background: ${(props) => props.color || '#380fd1'};
  svg {
    color: #fff;
  }
  #browse {
    padding: 0.5em 1.5em 0.5em;
    cursor: pointer;
    border-radius: 1.5rem;
    background: #380fd1;
    color: white;
    font-size: 20px;
    font-weight: 600;
    :hover {
      opacity: 0.7;
    }
    margin-top: 1em;
  }
  #image-upload {
    display: none;
  }
  #image-preview {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  }
  #delete {
    border-radius: 50px;
    border: none;
    width: 100px;
    opacity: 0.85;
    cursor: pointer;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5em;
    color: white;
  }
`;

const ImageValidationPanel = ({
  selectedImage,
  setSelectedImage,
  actionStyle = {},
}) => {
  const [itemDrag, setItemDrag] = useState(false);
  const [selectedImagePreview, setSelectedImagePreview] = useState();
  const [fileTypeError, setFileTypeError] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const activeStepContext = useActiveStepContext();

  const dragOverHandler = (e) => {
    e.preventDefault();
    setItemDrag(true);
  };

  const dragLeaveHandler = (e) => {
    setItemDrag(!itemDrag);
  };

  const dropHandler = (e) => {
    // if the file is uploaded from the input button
    if (e.target.files) {
      setSelectedImage(e.target.files[0]);
      let url = URL.createObjectURL(e.target.files[0]);
      setSelectedImagePreview(url);
      setSelectedImageUrl(url); 
      // if the file is uploaded from the drag and drop box
    } else if (e.dataTransfer.files) {
      e.preventDefault();
      setItemDrag(!itemDrag);
      if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
        setSelectedImage(null);
        return;
      }
      let fileType = e.dataTransfer.files[0].type;
      let validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (validTypes.includes(fileType)) {
        setSelectedImage(e.dataTransfer.files[0]);
        let url = URL.createObjectURL(e.dataTransfer.files[0]);
        setSelectedImagePreview(url);
        setSelectedImageUrl(url);
      } else {
        setFileTypeError(true);
      }
    }
  };

  const translation = useTranslation();

  return (
    <>
      <UploadPhotoTitlePopover />
      <Box
        sx={{
          width: { md: '100%' },
          maxWidth: { xs: '100%', md: '28rem'},
        }}
      >
        {fileTypeError && (
          <Alert severity="error" onClose={() => setFileTypeError(false)}>
            {translation.imageValidationError}
          </Alert>
        )}
        <Dropbox
          onDragOver={dragOverHandler}
          onDragLeave={dragLeaveHandler}
          onDrop={dropHandler}
          itemdraggedover={itemDrag ? 1 : 0}
          color={
            activeStepContext?.actionStyle
              ? activeStepContext?.actionStyle?.color
              : actionStyle?.color
          }
        >
          {selectedImage ? (
            <>
              <Typography
                component="div"
                variant="subtitle2"
                sx={{ my: '0.5em', fontWeight: '800' }}
                color={theme.palette.common.dark}
              >
                {translation.imageValidationSelected}
              </Typography>
              <Box id="image-preview" sx={{ width: '100%' }}>
                <Box
                  component="img"
                  sx={{
                    maxHeight: { xs: 110, md: 350 },
                    maxWidth: { xs: 110, md: 350 },
                    mt: '1em',
                  }}
                  alt="Uploaded Action Icon"
                  src={selectedImagePreview}
                ></Box>
                <button
                  id="delete"
                  style={{ backgroundColor: '#262C2A', margin: '0.5rem 0' }}
                  onClick={() => setSelectedImage(null)}
                >
                  <Delete />
                  {translation.delete}
                </button>
              </Box>
            </>
          ) : (
            <>
              <CameraAlt sx={{ fontSize: '120px' }} />
              <label htmlFor="image-upload" id="browse">
                <Typography variant="div">{translation.selectFile}</Typography>
              </label>
              <input
                accept="image/*"
                id="image-upload"
                type="file"
                onChange={dropHandler}
              />
            </>
          )}
        </Dropbox>
      </Box>
    </>
  );
};

export default ImageValidationPanel;
