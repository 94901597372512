import { createTheme } from '@mui/material/styles';
import { getGradientBackgroundStyle } from './utils/get-gradient-background-style';
import { darken } from '@mui/system';

const globalTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#BCF10C',
    },
    secondary: {
      light: '#B8E7FB',
      mid: '#4FC3F7',
      main: '#380FD1',
    },
    tertiary: {
      light: '#CCE8CD',
      main: '#26AAB7',
    },
    error: {
      main: '#ff8512',
    },
    invalid: {
      main: '#ff8512',
    },
    darkTheme: {
      main: '#262a2c',
      card: '#16191f',
    },
    common: {
      light: '#fff',
      dark: '#262a2c',
      black: '#000',
      pink: '#FF9CD5',
      orangelt: '#FFAC5E',
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
    ].join(','),
  },
});
const theme = createTheme(
  {
    components: {
      MuiSvgIcon: {
        variants: [
          {
            props: {
              variant: 'invalid',
            },
            style: {
              color: '#ff8512',
            },
          },
        ],
      },
      MuiPaper: {
        variants: [
          {
            props: {
              variant: 'gradient',
            },
            style: {
              background: getGradientBackgroundStyle(globalTheme),
              color: globalTheme.palette.common.dark,
              borderRadius: '0',
              [globalTheme.breakpoints.up('md')]: {
                borderRadius: '1.25rem',
              },
              '.MuiTypography-root': {
                color: globalTheme.palette.common.dark,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            backgroundColor: globalTheme.palette.common.dark,
            color: globalTheme.palette.common.light,
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            color: globalTheme.palette.common.light,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: globalTheme.palette.common.light,
          },
        },
        variants: [
          {
            props: {
              variant: 'contained',
            },
            style: {
              background: globalTheme.palette.secondary.main,
              color: globalTheme.palette.common.light,
              fontSize: '1.125rem',
              fontWeight: 900,
              lineHeight: '1.5em',
              padding: '0.35em 1.01em 0.45em',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: darken(globalTheme.palette.secondary.main, 0.5),
              },
            },
          },
          {
            props: {
              variant: 'outlined',
            },
            style: {
              fontSize: '1.125rem',
              fontWeight: 900,
              lineHeight: '1.5em',
              padding: '0.35em 1.01em 0.45em',
              textTransform: 'none',
              boxSizing: 'border-box'
            },
          },
          {
            props: {
              variant: 'text',
            },
            style: {
              padding: 0,
              textDecoration: 'underline',
              fontSize: '1em',
              fontWeight: 700,
              textTransform: 'none',
            },
          },
          {
            props: {
              variant: 'text-dark',
            },
            style: {
              padding: 0,
              textDecoration: 'underline',
              fontSize: '1em',
              fontWeight: 700,
              textTransform: 'none',
              color: globalTheme.palette.common.dark,
            },
          },
        ],
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            '&.progress-signup': {
              background: globalTheme.palette.common.dark,
              borderWidth: '1px',
              borderLeftWidth: '2px',
              borderColor: globalTheme.palette.common.dark,
              borderStyle: 'solid',
              borderRadius: '10px',
              height: '8px',
              '.MuiLinearProgress-bar': {
                background: 'linear-gradient(90deg, #26AAB7 0%, #8EDAAC 100%)',
                borderRadius: '10px',
                borderWidth: '1px',
                borderColor: globalTheme.palette.common.dark,
                borderStyle: 'solid',
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        variants: [
          {
            props: {
              variant: 'lightPill',
            },
            style: {
              backgroundColor: 'rgba(255, 255, 255, 10%)',
              borderWidth: '1px',
              borderRadius: '20px',
              borderStyle: 'solid',
              display: 'block',
              borderColor: globalTheme.palette.common.light,
              textAlign: 'left',
              padding: '1em 2em',
              '&.checked': {
                borderColor: globalTheme.palette.common.dark,
              },
              '& .MuiTypography-root': {
                color: globalTheme.palette.common.dark,
                fontSize: '1.125em',
                fontWeight: '700',
                margin: '0',
              },
            },
          },
        ],
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: '#131516',
          },
        },
      },
      MuiFormLabel: {
        fontSize: '18px',
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: '#212426',
            backgroundImage: 'none',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: '#',
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          main: '#404642',
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            backgroundColor: '#0d0f0f',
          },
        },
      },
      MuiTypography: {
        variants: [
          {
            props: {
              variant: 'p',
            },
            style: {
              fontSize: '1.15rem',
              color: '#fff',
            },
          },
          {
            props: {
              variant: 'h1',
            },
            style: {
              [globalTheme.breakpoints.up('md')]: {
                fontSize: 60,
              },
              fontSize: 40,
              color: '#fff',
              fontWeight: 600,
            },
          },
          {
            props: {
              variant: 'h2',
            },
            style: {
              fontSize: 30,
              color: '#fff',
              fontWeight: 500,
              letterSpacing: -0.85,
            },
          },
          {
            props: {
              variant: 'h3',
            },
            style: {
              fontSize: 18,
              color: '#fff',
              fontWeight: 400,
            },
          },

          {
            props: {
              variant: 'h4',
            },
            style: {
              fontSize: 25,
              color: '#000',
              fontWeight: 600,
            },
          },
          {
            props: {
              variant: 'h5',
            },
            style: {
              lineHeight: 1.334,
              fontSize: 'calc(2vh + 2vw)',
              color: 'rgb(0, 0, 0)',
            },
          },
          {
            props: {
              variant: 'h6',
            },
            style: {
              color: '#5BD048',
              fontWeight: 400,
            },
          },
          {
            props: {
              variant: 'h7',
            },
            style: {
              fontSize: 20,
              color: '#fff',
              fontWeight: 400,
            },
          },
          {
            props: {
              variant: 'subtitle1',
            },
            style: {
              fontSize: '10',
              color: '#fff',
              fontWeight: 400,
            },
          },
          {
            props: {
              variant: 'subtitle2',
            },
            style: {
              fontSize: 17,
              color: '#BCF10C',
              fontWeight: 400,
            },
          },
          {
            props: {
              variant: 'body1',
            },
            style: {
              fontSize: 16,
              color: '#fff',
              fontWeight: 400,
            },
          },
        ],
      },
    },
  },
  globalTheme
);

export default theme;
