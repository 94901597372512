import React from 'react';
import { CircularProgress } from '@mui/material';
import { useActionFactImage } from '../../hooks/use-action-fact-image';

const ActionFactImage = () => {
  const { randomImage, isLoading } = useActionFactImage();

  return !isLoading ? (
    randomImage ? (
      <figure
        style={{
          position: 'relative',
          margin: '2rem 0 1rem',
          width: 'fit-content',
          maxWidth: '88vw',
          overflow: 'hidden',
          borderRadius: '1.5rem'
        }}
      >
        <img
          height="300px"
          src={randomImage['Thumb228']}
          alt={randomImage?.title}
          style={{ borderRadius: '1.5rem'}}
        />
        <figcaption
          style={{
            position: 'absolute',
            bottom: '4px',
            right: '0',
            color: 'white',
            fontSize: '14px',
            background: '#303839',
            padding: '0.5rem',
            borderRadius: '.5rem 0 .25rem 0',
          }}
        >
          Photo by {randomImage['Artist']} from {randomImage['Country']}
        </figcaption>
      </figure>
    ) : null
  ) : (
    <CircularProgress aria-label="loading image" />
  );
};

export default ActionFactImage;
