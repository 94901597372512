import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const WEIGHT_UNITS = {
  GRAM: 'g',
  KILO: 'kg',
};

const WEIGHT_UNIT_NAMES = {
  [WEIGHT_UNITS.GRAM]: 'grams',
  [WEIGHT_UNITS.KILO]: 'kilograms',
};

const textProps = {
  component: 'span',
  sx: {
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
};

const roundWeight = (weight) =>
  Math.round((weight + Number.EPSILON) * 100) / 100;

export const DisplayWeight = ({
  weight,
  unit = WEIGHT_UNITS.GRAM,
  hideUnit = false,
}) => {
  let displayWeight = parseFloat(weight);
  let displayUnit = unit;

  if (unit === WEIGHT_UNITS.GRAM && weight >= 1000) {
    displayWeight = weight / 1000;
    displayUnit = WEIGHT_UNITS.KILO;
  }

  const displayRoundedWeight = roundWeight(displayWeight).toLocaleString();

  return (
    <Typography {...textProps}>
      {displayRoundedWeight}
      {!hideUnit ? (
        <>
          <Typography aria-hidden="true" {...textProps}>
            {displayUnit}
          </Typography>
          <Typography sx={visuallyHidden}>
            {WEIGHT_UNIT_NAMES[displayUnit]}
          </Typography>
        </>
      ) : null}
    </Typography>
  );
};

DisplayWeight.propTypes = {
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.oneOf(['g', 'kg']),
  hideUnit: PropTypes.bool,
};
