import React, { useEffect } from 'react';
import { familiarityOptions } from '../constants/familiarity-options';
import { useUpdateCurrentUserProfile } from './use-update-current-user-profile.hook';
import { useGetCurrentUserProfile } from './use-get-current-user-profile.hook';

export const defaultFamiliarity = '';

export const useSignupSubjectFamiliar = () => {
  const { userProfile } = useGetCurrentUserProfile();
  const { updateUserProfile } = useUpdateCurrentUserProfile();
  const [familiarity, setFamiliarity] = React.useState(defaultFamiliarity);

  // Temporary stand-in for API request method
  const sendFamiliarityRequest = async () =>
    await updateUserProfile({ 'custom:familiar': familiarity });

  useEffect(() => {
    if (
      userProfile !== undefined &&
      userProfile['custom:familiar'] !== defaultFamiliarity
    ) {
      setFamiliarity(userProfile['custom:familiar']);
    }
  }, [userProfile]);

  return {
    familiarity,
    familiarityOptions,
    setFamiliarity,
    sendFamiliarityRequest,
  };
};
