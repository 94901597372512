import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton } from '@mui/material';
import { BaseComponent } from '../../prop-types/component';

export const Button = ({ children, variant, dark, sx, ...props }) => {
  const darkColor = '#262a2c';

  return (
    <MuiButton
      variant={variant}
      {...props}
      sx={{
        ...sx,
        borderColor: dark ? darkColor : undefined,
        color: dark ? darkColor : '#fff',
      }}
    >
      {children}
    </MuiButton>
  );
};

Button.propTypes = {
  ...BaseComponent,
  variant: PropTypes.string,
};
