export const USER_TYPE = {
  MEMBER: 'User',
  ADMIN: 'Admin',
};

export const getSimpleUserFromAttributes = (userAttributes) => {
  if (!userAttributes) return undefined;
  const type = userAttributes['custom:type'] || '';
  const ignoreActions = userAttributes['custom:ignoreActions'] ? userAttributes['custom:ignoreActions'].split(',') : []
  const preferActions = userAttributes['custom:preferActions'] ? userAttributes['custom:preferActions'].split(',') : []
  return {
    id: userAttributes['custom:id'] || '',
    email: userAttributes.email || '',
    name: userAttributes.name || '',
    username: userAttributes.preferred_username || '',
    isAdmin: type === USER_TYPE.ADMIN,
    type,
    ageRange: userAttributes['custom:ageRange'] || '',
    avatar: userAttributes['custom:avatar'] || '',
    familiar: userAttributes['custom:familiar'] || '',
    ignoreActions: ignoreActions,
    intendUse: userAttributes['custom:intendUse'] || '' ,
    preferActions: preferActions,
    province: userAttributes['custom:province'] || '',
    country: userAttributes['custom:country'] || '',
  };
};
