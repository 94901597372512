import * as React from 'react';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { API } from 'aws-amplify';
import { LogActionContainer } from '../../../views/LogActionContainer/LogActionContainer';
import { Button } from '../../../components/Button/Button';
import { getLogActionPath } from '../../../constants/log-action-paths';
import { useGetSingleAction } from '../../../hooks/use-get-single-action';
import { useGetActionQuiz } from '../../../hooks/use-get-action-quiz.hook';
import { useGetUserAction } from '../../../hooks/use-get-user-action';
import { updateSubmittedAction } from '../../../graphql/mutations';
import { ACTION_POINTS_VALUES } from '../../../constants/action-points-values';
import theme from '../../../themes';
import useTranslation from '../../../components/customHooks/translations';


const { BONUS_FULL, BONUS_PARTIAL } = ACTION_POINTS_VALUES;



export const BonusQuiz = () => {
  const navigate = useNavigate();
  const { actionId, userActionId } = useParams();
  const { actionStyles } = useGetSingleAction();
  const { userAction } = useGetUserAction();
  const { actionQuiz: quiz, loading: loadingQuiz } = useGetActionQuiz();
  const { userActionValues, setUserActionValues } = useOutletContext();

  const [answer, setAnswer] = React.useState('');
  const [status, setStatus] = React.useState(null);
  const [firstAnswer, setFirstAnswer] = React.useState(true);

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const translation = useTranslation();

  // eslint-disable-next-line react/prop-types
  const CorrectMessage = ({ firstAnswer }) => (
    <Typography variant="p" component="p" mt="1.5rem">
      <strong style={{ color: '#56C573' }}>{translation.correct}</strong>
      {translation.formatString(translation['logActionPage'].greatJob, firstAnswer ? BONUS_FULL : BONUS_PARTIAL)}
    </Typography>
  );

  const IncorrectMessage = () => (
    <Typography variant="p" component="p" mt="1.5rem">
      <strong style={{ color: '#EB5757' }}>{translation.incorrect}</strong>
      {translation.formatString(translation['logActionPage'].tryAgain, BONUS_PARTIAL)}
    </Typography>
  );
  const onSubmitForm = (e) => {
    e.preventDefault();

    if (status === 'correct') {
      navigate(getLogActionPath('SHARE_SOCIAL', actionId, userActionId));
    } else {
      setStatus(answer === quiz?.answer ? 'correct' : 'incorrect');
    }
  };

  const onChangeAnswer = (e) => {
    setStatus(null);
    setAnswer(e.target.value);
  };

  React.useEffect(() => {
    const updateUserAction = async () => {
      setLoading(true);
      const pointsEarned = firstAnswer
        ? userAction?.pointsEarned + BONUS_FULL
        : userAction?.pointsEarned + BONUS_PARTIAL;
 
      setUserActionValues({ ...userActionValues, pointsEarned });

    
      try {
        const variables = {
          sa_id: userAction?.id,
          user_id: userAction?.userId,
          action_id: parseInt(actionId),
          g_co2_saved: userAction?.co2Saved,
          date_of_action: userAction?.dateOfAction,
          is_validated: userAction?.isValidated,
          quiz_answered: true,
          first_quiz_answer_correct: firstAnswer,
          points_earned: pointsEarned,
        };

        const response = await API.graphql({
          query: updateSubmittedAction,
          variables,
        });
    
        setSuccess(true);
        setLoading(false);
      } catch (e) {
        console.log('Error: ', e);
        setSuccess(false);
        setLoading(false);
      }
    };
    if (!loading && success === null && status === 'correct') {
      updateUserAction();
    }
  }, [
    loading,
    success,
    status,
    userAction,
    firstAnswer,
    quiz,
    actionId,
    setUserActionValues,
    userActionValues,
  ]);

  React.useEffect(() => {
    if (status === 'incorrect') {
      setFirstAnswer(false);
    }
  }, [status]);

  React.useEffect(() => {
    if (quiz?.options.length > 0 && answer === '') {
      setAnswer(quiz?.options[0]);
    }
  }, [answer, quiz]);

  return (
    <LogActionContainer
      step={4}
      buttons={
        <>
          <Button
            component={Link}
            to={getLogActionPath('SHARE_SOCIAL', actionId, userActionId)}
            variant="outlined"
            sx={{border: '1px solid white', [theme.breakpoints.down('sm')]: {order: '2'}}}
          >
            {translation.skip}
          </Button>
          <Button
            variant="contained"
            type="submit"
            form="form-BonusQuiz"
            disabled={loading}
          >
            {status === 'correct' ? translation.continue : translation.submit}
          </Button>
        </>
      }
    >
      <Typography
        variant="h1"
        sx={{
          color: actionStyles?.color,
        }}
      >
        {translation.logActionStep5}!
      </Typography>
      <Typography
        variant="p"
        component="p"
        sx={{ color: '#56C573', mt: '2rem' }}
      >
        {translation.extraPoints}
      </Typography>
      {!loadingQuiz && (
        <Box component="form" id="form-BonusQuiz" onSubmit={onSubmitForm}>
          <Box
            component="fieldset"
            sx={{ margin: 0, padding: 0, outline: 'none', border: 'none' }}
          >
            <Typography
              variant="p"
              component="legend"
              id="bonus-quiz-legend"
              sx={{ padding: 0, margin: '1rem 0' }}
            >
              {quiz?.question}
            </Typography>
            <RadioGroup
              aria-labelledby="bonus-quiz-legend"
              aria-describedby="bonus-quiz-status"
              name="bonus-quiz"
              value={answer}
              onChange={onChangeAnswer}
            >
              {quiz?.options.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
            <Box aria-live="polite" id="bonus-quiz-status">
              {status === 'correct' ? (
                <CorrectMessage firstAnswer={firstAnswer} />
              ) : null}
              {status === 'incorrect' ? <IncorrectMessage /> : null}
            </Box>
          </Box>
        </Box>
      )}
    </LogActionContainer>
  );
};
