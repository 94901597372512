import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper as MuiPaper } from '@mui/material';
import { BaseComponent } from '../../prop-types/component';
import { useTheme } from '@mui/material/styles';

const celebrateEffectStyles = {
  overflow: 'hidden',
  '&:before': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
    display: 'block',
    top: 0,
    left: 0,
    background: 'url(/assets/images/celebrate-tile.svg) repeat',
  },
};

export const Paper = ({
  effect = '',
  children,
  variant = '',
  sx = {},
  ...props
}) => {
  const theme = useTheme(); // theme object
  let effectStyles = {};

  if (effect === 'celebrate') {
    effectStyles = celebrateEffectStyles;
  }

  return (
    <MuiPaper
      variant={variant}
      sx={{
        ...sx,
        ...effectStyles,
        position: 'relative',
        maxWidth: '72rem',
        padding: theme.spacing(6),
        boxSizing: 'border-box',
        width: '92vw',
      }}
      {...props}
    >
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        {children}
      </Box>
    </MuiPaper>
  );
};

Paper.propTypes = {
  ...BaseComponent,
  effect: PropTypes.oneOf(['celebrate', '']),
  variant: PropTypes.oneOf(['elevation', 'outlined', 'gradient', '']),
};
