import { PAGE_PATHS } from './page-paths';

export const ONBOARD_PATH = '/onboard';
const ONBOARD_START = ONBOARD_PATH + '/details';
const ONBOARD_END = ONBOARD_PATH + '/complete';

const getSignupStep = (step = 1) => ONBOARD_PATH + `/step-${step}`;

export const SIGNUP_PATHS = {
  INFO_PRIMARY: PAGE_PATHS.SIGNUP,
  USER_CONFIRM: PAGE_PATHS.CONFIRM,
  INFO_SECONDARY: ONBOARD_START,
  ONBOARD_START: getSignupStep(1),
  AVATAR_SELECT: getSignupStep(2),
  AVATAR_REVIEW: getSignupStep(3),
  SUBJECT_FAMILIAR: getSignupStep(4),
  SUBJECT_FACT: getSignupStep(5),
  USAGE_INTEND: getSignupStep(6),
  USAGE_INSTRUCT: getSignupStep(7),
  ACTIONS_SELECT: getSignupStep(8),
  ACTIONS_IMPACT: getSignupStep(9),
  ACTIONS_IGNORE: getSignupStep(10),
  ONBOARD_END,
};

export const SIGNUP_NAMES = {
  INFO_PRIMARY: 'InfoPrimary',
  USER_CONFIRM: 'UserConfirm',
  INFO_SECONDARY: 'InfoSecondary',
  ONBOARD_START: 'OnboardStart',
  AVATAR_SELECT: 'AvatarSelect',
  AVATAR_REVIEW: 'AvatarReview',
  SUBJECT_FAMILIAR: 'SubjectFamiliar',
  SUBJECT_FACT: 'SubjectFact',
  USAGE_INTEND: 'UsageIntend',
  USAGE_INSTRUCT: 'UsageInstruct',
  ACTIONS_SELECT: 'ActionsSelect',
  ACTIONS_IMPACT: 'ActionsImpact',
  ACTIONS_IGNORE: 'ActionsIgnore',
  ONBOARD_END: 'OnboardEnd',
};

const defaultButtons = { back: 'buttonBack', next: 'buttonNext' };

export const SIGNUP_BUTTONS = {
  INFO_PRIMARY: {
    ...defaultButtons,
    next: 'buttonSignup',
    formId: `form-${SIGNUP_NAMES.INFO_PRIMARY}`,
  },
  USER_CONFIRM: {
    ...defaultButtons,
    next: 'buttonConfirm',
    formId: `form-${SIGNUP_NAMES.USER_CONFIRM}`,
  },
  INFO_SECONDARY: {
    back: 'buttonBack',
    next: 'buttonComplete',
    formId: `form-${SIGNUP_NAMES.INFO_SECONDARY}`,
  },
  ONBOARD_START: { next: 'buttonLetsGo', skip: true },
  AVATAR_SELECT: {
    ...defaultButtons,
    formId: `form-${SIGNUP_NAMES.AVATAR_SELECT}`,
  },
  AVATAR_REVIEW: defaultButtons,
  SUBJECT_FAMILIAR: {
    ...defaultButtons,
    formId: `form-${SIGNUP_NAMES.SUBJECT_FAMILIAR}`,
  },
  SUBJECT_FACT: defaultButtons,
  USAGE_INTEND: {
    ...defaultButtons,
    formId: `form-${SIGNUP_NAMES.USAGE_INTEND}`,
  },
  USAGE_INSTRUCT: defaultButtons,
  ACTIONS_SELECT: {
    ...defaultButtons,
    formId: `form-${SIGNUP_NAMES.ACTIONS_SELECT}`,
  },
  ACTIONS_IMPACT: defaultButtons,
  ACTIONS_IGNORE: {
    ...defaultButtons,
    formId: `form-${SIGNUP_NAMES.ACTIONS_IGNORE}`,
  },
  ONBOARD_END: { next: 'buttonLetsTakeAction' },
};

const signupPathsKeys = Object.keys(SIGNUP_PATHS);
const signupProgressTotal = signupPathsKeys.length - 1;

export const SIGNUP_ROUTES = signupPathsKeys.map((key, idx) => {
  const step = idx + 1;
  return {
    key,
    name: SIGNUP_NAMES[key],
    path: SIGNUP_PATHS[key],
    progress: Math.ceil((idx / signupProgressTotal) * 100),
    buttons: SIGNUP_BUTTONS[key],
    step,
  };
});

export const PRIVATE_SIGNUP_ROUTES = SIGNUP_ROUTES.filter(
  (route) =>
    route.path !== PAGE_PATHS.SIGNUP && route.path !== PAGE_PATHS.CONFIRM
);
