import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BaseComponent } from '../../../prop-types/component';
import useTranslation from '../../../components/customHooks/translations';
import { SIGNUP_NAMES, SIGNUP_PATHS } from '../../../constants/signup-paths';
import { useSignupIgnoreActions } from '../../../hooks/use-signup-ignore-actions.hook';
import { MultiSelectTileGroup } from '../../../components/MultiSelectTile/MultiSelectTileGroup';
import { LoadingSpinner } from '../LoadingSpinner';

export const ActionsIgnore = () => {
  const { signup } = useTranslation();
  const navigate = useNavigate();
  const { actionOptions, setIgnoreActions, sendIgnoreActionsRequest } =
    useSignupIgnoreActions();
  const [loading, setLoading] = React.useState(false);

  const [selectedItems, setSelectedItems] = React.useState([]);
  React.useEffect(() => {
    setIgnoreActions(selectedItems);
  }, [selectedItems, setIgnoreActions]);

  const onSubmitForm = async (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);

      try {
        await sendIgnoreActionsRequest();
        setLoading(false);
        navigate(SIGNUP_PATHS.ONBOARD_END);
      } catch (e) {
        setLoading(false);
        console.log('Error: ', e);
      }
    }
  };
  return (
    <Box data-testid="actions-ignore">
      <Typography variant="h1">{signup.actionsIgnoreHeading}</Typography>
      <Typography
        variant="p"
        component="p"
        sx={{
          lineHeight: '1.5',
          maxWidth: '600px',
          margin: '0 auto',
          paddingTop: '1.25em',
        }}
      >
        {signup.actionsIgnoreSubheading}
      </Typography>
      <Box
        id={`form-${SIGNUP_NAMES.ACTIONS_IGNORE}`}
        component="form"
        onSubmit={onSubmitForm}
      >
        <MultiSelectTileGroup
          selectionOptions={actionOptions}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          ariaLabel="ignore-actions-select-label"
          name="ignore-actions"
        />
      </Box>

      <LoadingSpinner loading={loading} />
    </Box>
  );
};

ActionsIgnore.propTypes = {
  ...BaseComponent,
};
