import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { BaseComponent } from '../../../prop-types/component';
import useTranslation from '../../../components/customHooks/translations';
import { useAvatar } from '../../../hooks/use-get-avatar.hook';

export const OnboardEnd = () => {
  const { signup } = useTranslation();
  const { avatar, isLoading } = useAvatar();
  return (
    <Box data-testid="onboard-end">
      <Typography variant="h1">{signup.onboardEndHeading}</Typography>
      <Typography
        variant="p"
        component="p"
        sx={{
          lineHeight: '1.5',
          maxWidth: '600px',
          margin: '0 auto',
          paddingTop: '1.25em',
        }}
      >
        {signup.onboardEndSubheading}
      </Typography>
      {!isLoading && (
        <Box
          style={{
            position: 'relative',
            maxHeight: '40vh',
            maxWidth: '80%',
            width: '100%',
            paddingTop: '50%',
            margin: '0 auto',
          }}
        >
          <Box
            component="img"
            alt=""
            src={avatar.animatedUrl || avatar.imageUrl}
            sx={{
              width: '100%',
              height: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
              position: 'absolute',
              inset: 0,
            }}
            my="1em"
          />
        </Box>
      )}
    </Box>
  );
};

OnboardEnd.propTypes = {
  ...BaseComponent,
};
