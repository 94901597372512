import * as React from 'react';
import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Box, Typography, CircularProgress } from '@mui/material';
import { BaseComponent } from '../../../prop-types/component';
import useTranslation from '../../../components/customHooks/translations';
import { getAllQuizzes } from '../../../graphql/queries';
import { useActionFactImage } from '../../../hooks/use-action-fact-image';

export const SubjectFact = () => {
  const { signup } = useTranslation();
  const [quiz, setQuiz] = useState(null);
  const [noPossibleQuizzes, setNoPossibleQuizzes] = useState(false);

  useEffect(() => {
    const fetchQuiz = async () => {
      const getAllQuizzesFact = await API.graphql({
        query: getAllQuizzes,
      });

      const quizPool = getAllQuizzesFact.data.getAllQuizzes;
      if (quizPool.length > 0) {
        const randomQuiz =
          quizPool[Math.floor(Math.random() * quizPool.length)];
        setQuiz(randomQuiz);
      } else {
        setNoPossibleQuizzes(true);
      }
    };

    fetchQuiz();
  }, []);

  const renderFact = () => {
    if (quiz) {
      return (
        <Typography
          variant="p"
          component="p"
          sx={{
            lineHeight: '1.5',
            maxWidth: '600px',
            textAlign: 'center',
            margin: '0 auto',
          }}
        >
          {quiz.fact_text}
        </Typography>
      );
    } else if (noPossibleQuizzes) {
      return <Typography variant="h3" component="p"></Typography>;
    }
  };

  const { randomImage } = useActionFactImage();

  return (
    <Box data-testid="subject-fact">
      {randomImage ? (
        <figure
          style={{
            position: 'relative',
            margin: '1.5rem auto 2rem',
            maxWidth: '342px',
          }}
        >
          <img
            height="250px"
            src={randomImage['Thumb228']}
            alt={randomImage?.title}
            style={{ borderRadius: '1.5rem 1.5rem 0 0', maxWidth: '100%' }}
          />
          <figcaption
            style={{
              position: 'relative',
              bottom: '0',
              right: '0',
              color: 'white',
              fontSize: '14px',
              background: '#262a2c',
              padding: '0.5em',
              width: '100%',
              borderRadius: '0 0 1.5rem 1.5rem',
              boxSizing: 'border-box',
              marginTop: '-10px',
            }}
          >
            Photo by {randomImage['Artist']} from {randomImage['Country']}
          </figcaption>
        </figure>
      ) : (
        <CircularProgress aria-label="Loading quizzes" />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h1" mb=".5em">
          {signup.subjectFactHeading}
        </Typography>
        <Typography variant="p" sx={{lineHeight: '1.5'}}>
          Youth are leading the response to climate change!
          <br />
          Both public opinion and the Supreme Court are aligned with climate action.
          <br />
          Decarbonizing the economy will create millions of well paying jobs.
        </Typography>

      </Box>
    </Box>
  );
};

SubjectFact.propTypes = {
  ...BaseComponent,
};
