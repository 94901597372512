import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio as MuiRadio,
  FormControlLabel,
  Box,
  Typography,
} from '@mui/material';
import { BaseComponent } from '../prop-types/component';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { visuallyHidden } from '@mui/utils';

export const Radio = ({
  id,
  title,
  value,
  checked = false,
  variant,
  onChange,
  ...props
}) => {
  const handleClick = () => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <FormControlLabel
      key={id}
      variant={variant}
      checked={checked}
      value={value}
      control={<CheckCircleIcon sx={visuallyHidden} />}
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography display="inline" sx={{ textAlign: 'left' }}>
            {title}
          </Typography>
          <MuiRadio
            value={value}
            icon={
              <CheckCircleIcon
                sx={visuallyHidden}
              />
            }
            checkedIcon={<CheckCircleIcon sx={{ color: 'black' }} />}
            checked={checked}
            onChange={handleClick}
          />
        </Box>
      }
      className={checked ? 'checked' : ''}
      onClick={handleClick}
      {...props}
    />
  );
};

Radio.propTypes = {
  ...BaseComponent,
  id: PropTypes.string,
  checked: PropTypes.bool,
  variant: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
