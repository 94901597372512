import { useCallback, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { getAllActions } from '../graphql/queries';
import { getSimpleActionFromResponse } from '../utils/get-simple-action-from-response';
import { useContentTranslationsContext } from '../components/contexts/ContentTranslationsContext';
import { useLanguageContext } from '../components/contexts/LanguageContext';

export const useGetAllActions = () => {
  const { language } = useLanguageContext();
  const { contentTranslations } = useContentTranslationsContext();


  const [allActions, setAllActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const translateActionsToFrench = (actions) => {
    const frenchTranslations = contentTranslations?.find((translation) => translation.langCode === 'fr');

    if (!frenchTranslations || !frenchTranslations.translationJSON?.actions) {
      return actions;
    }

    return actions.map((action) => {
      let frenchAction = frenchTranslations.translationJSON.actions.find(
        (frenchActionObj) => frenchActionObj.action_id === action.action_id
      );

      if (!frenchAction) {
        return action;
      }

      return { ...action, ...frenchAction };
    });
  };

  const translateActionsToSpanish = (actions) => {
    const spanishTranslations = contentTranslations?.find((translation) => translation.langCode === 'es');

    if (!spanishTranslations || !spanishTranslations.translationJSON?.actions) {
      return actions;
    }

    return actions.map((action) => {
      let spanishAction = spanishTranslations.translationJSON.actions.find(
        (spanishActionObj) => spanishActionObj.action_id === action.action_id
      );

      if (!spanishAction) {
        return action;
      }

      return { ...action, ...spanishAction };
    });
  };

  const fetchAllActions = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await API.graphql({ query: getAllActions });
      let actions = response?.data?.getAllActions;

      if (!actions) {
        setError('No actions found');
        setLoading(false);
        return;
      }

      switch (language) {
        case 'en': {
          setAllActions(actions.map(getSimpleActionFromResponse));
          break;
        }
        case 'fr': {
          const translatedActions = translateActionsToFrench(actions);
          setAllActions(translatedActions.map(getSimpleActionFromResponse));
          break;
        }
        case 'es': {
          const translatedActions = translateActionsToSpanish(actions);
          setAllActions(translatedActions.map(getSimpleActionFromResponse));
          break;
        }
        default: {
          setAllActions(actions.map(getSimpleActionFromResponse));
          break;
        }
      }

      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, [language, contentTranslations]);

  useEffect(() => {
    fetchAllActions();
  }, [fetchAllActions]);

  return {
    allActions,
    availableActions: allActions.filter((action) => !action?.hidden),
    loading,
    error,
  };
};