import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  ImageListItemBar,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ImageListItem, {
  imageListItemClasses,
} from '@mui/material/ImageListItem';
import { getAllUngraveyardedActions } from '../graphql/queries';
import { API } from 'aws-amplify';
import { styled } from '@mui/material/styles';
import useTranslation from './customHooks/translations';
import { useContentTranslationsContext } from './contexts/ContentTranslationsContext';
import { useAvatar } from '../hooks/use-get-avatar.hook';
import { number } from 'prop-types';
import { ActionStyles } from '../hooks/use-active-step-context';

const StyledImageListItemBar = styled(ImageListItemBar)`
  .MuiImageListItemBar-title {
    overflow: visible;
    white-space: normal;
    overflow-wrap: break-word;
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
  }
`;

const StyledImageListItem = styled(ImageListItem)`
  .MuiImageListItem-img {
    border-radius: 7px;
    margin-bottom: 8px;
    height: 120px;
    width: 100%;
    @media only screen and (min-width: 800px) {
      height: 278px;
    },
  }
`;

const actionColors = [
  {
    id: 1,
    color: '#B0F36D',
  },
  {
    id: 2,
    color: '#FF9CD5',
  },
  {
    id: 3,
    color: '#78F1F1',
  },
  {
    id: 5,
    color: '#9EF2BF',
  },
  {
    id: 11,
    color: '#FFAC5E',
  },
  {
    id: 12,
    color: '#FFE871',
  },
];
const AllActions = ({ setSelectedAction, setActiveStep, setActionStyle }) => {
  const [actionOptions, setActionOptions] = useState([]);
  const [loadingActions, setLoadingActions] = useState(false);
  const displayDefaultMsg = actionOptions && actionOptions.length === 0;

  const translation = useTranslation();
  const nav = useNavigate();
  const { contentTranslations } = useContentTranslationsContext();
  const { avatar } = useAvatar();

  const disassembleInto = (actions, translatedActions) => {
    return actions.map((action) => {
      const translatedAction = translatedActions.find(
        (translatedAction) => translatedAction.action_id === action.action_id
      );
      return {
        ...action,
        ...translatedAction,
      };
    });
  };

  const handleSelectedAction = (action) => {
    setSelectedAction(action);
    setActionStyle(ActionStyles[action.action_id] || ActionStyles[0]);
    setActiveStep(1);
    const actionUID = action.action_name
      .toLowerCase()
      .trim()
      .replaceAll(' ', '-');
    nav(`/log-action/${actionUID}`);
  };

  const getActionIcon = (id) => {
    const actionObject = avatar.actions.find((action) => action.id === id);
    if (actionObject) return actionObject.icon;
    return avatar.imageUrl;
  };

  const getActionColor = (id) => {
    const actionColor = actionColors.find((color) => color.id === id);
    if (actionColor) return actionColor.color;
    if (typeof id === number)
      return actionColors[id % actionColors.length].color;
    return actionColors[0].color;
  };

  const fetchAllActions = useCallback(async () => {
    setLoadingActions(true);
    try {
      const res = await API.graphql({ query: getAllUngraveyardedActions });
      const actions = res.data.getAllUngraveyardedActions;
      if (translation.getLanguage() !== 'en') {
        const relevantTranslationObject = contentTranslations.find(
          (contentTranslation) =>
            contentTranslation.langCode.toLowerCase() ===
            translation.getLanguage().toLowerCase()
        );
        const updatedActions = disassembleInto(
          actions,
          relevantTranslationObject?.translationJSON?.actions || []
        );
        setActionOptions(updatedActions);
      } else {
        setActionOptions(actions);
      }
      setLoadingActions(false);
    } catch (e) {
      console.log('Error:', e);
      setLoadingActions(false);
    }
  }, [contentTranslations, translation]);

  useEffect(() => {
    if (!loadingActions && actionOptions.length === 0) {
      fetchAllActions();
    }
  }, [actionOptions, fetchAllActions, loadingActions]);

  return (
    <Grid
      item
      sx={{
        minHeight: '50vh',
        display: displayDefaultMsg && 'flex',
        alignItems: displayDefaultMsg && 'center',
        justifyContent: displayDefaultMsg && 'center',
      }}
    >
      {loadingActions && <CircularProgress />}
      {displayDefaultMsg && (
        <Typography variant="subtitle2">No Actions to Display</Typography>
      )}
      <Box
        sx={{
          display: 'grid',
          gap: '10px',
          gridTemplateColumns: {
            xs: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          },
          rowGap: 2,
          [`& .${imageListItemClasses.root}`]: {
            display: 'flex',
            flexDirection: 'column',
          },
          justifyItems: 'center',
        }}
      >
        {actionOptions &&
          actionOptions.map((action, index) => (
            <StyledImageListItem
              component="button"
              key={index}
              sx={{
                width: '100%',
                height: '100px',
                cursor: 'pointer',
                background: 'none',
                borderRadius: '20px',
                padding: '0',
                boxShadow: '0',
                border: '0',
                '&:hover': {
                  opacity: '0.7',
                },
              }}
              onClick={() => handleSelectedAction(action)}
            >
              {action.action_icon ? (
                <img
                  src={getActionIcon(action.action_id)}
                  style={{
                    objectFit: 'contain',
                    boxSizing: 'border-box',
                    width: '248px',
                    padding: '0px',
                    background: getActionColor(action.action_id),
                  }}
                  loading="lazy"
                  aria-hidden="true"
                  alt=""
                />
              ) : (
                <Box
                  sx={{
                    backgroundColor: '#B4EEB4	',
                    width: '100%',
                    height: '100px',
                    borderRadius: '7px',
                  }}
                ></Box>
              )}
              <StyledImageListItemBar
                sx={{ width: '100%' }}
                title={action.action_name}
                position="below"
              />
            </StyledImageListItem>
          ))}
      </Box>
    </Grid>
  );
};

export default AllActions;
