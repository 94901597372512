import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link, Outlet } from 'react-router-dom';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Paper } from '../../components/Paper/Paper';
import { LinearProgress } from '../../components/LinearProgress/LinearProgress';
import { usePageContainerStyles } from '../../styles/page-container';
import { SIGNUP_ROUTES } from '../../constants/signup-paths';
import { PAGE_PATHS } from '../../constants/page-paths';
import { BaseComponent } from '../../prop-types/component';
import useTranslation from '../../components/customHooks/translations';
import { useSignupStepProgress } from '../../hooks/use-signup-step-progress.hook';
import LoginNavbar from '../../components/authentication/LoginNavbar.js';
import { Button } from '../../components/Button/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const getRouteByStep = (step) =>
  SIGNUP_ROUTES.find((route) => route.step === step);

export const SignupPrevNextLink = ({
  navToStep,
  formId = null,
  children = null,
  backIcon = false,
  nextIcon = false,
  sx = {},
  ...props
}) => {
  if (!children) return null;

  let path;
  const lastStep = SIGNUP_ROUTES.length;

  if (navToStep < 1) path = PAGE_PATHS.LOGIN;
  else if (navToStep > lastStep) path = PAGE_PATHS.LOG_ACTION;
  else path = getRouteByStep(navToStep)?.path;

  const buttonProps = {
    ...props,
    'data-to-path': path,
    sx: {
      ...sx,
      minWidth: '10em',
    },
  };

  if (formId !== null) {
    buttonProps.type = 'submit';
    buttonProps.form = formId;
  } else {
    buttonProps.component = Link;
    buttonProps.to = path;
  }

  return (
    <Button {...buttonProps}>
      {backIcon && <ArrowBackIosIcon />}
      {children}
      {nextIcon && <ArrowForwardIosIcon />}
    </Button>
  );
};

SignupPrevNextLink.propTypes = {
  ...BaseComponent,
  navToStep: PropTypes.number,
  backIcon: PropTypes.bool,
  nextIcon: PropTypes.bool,
};

export const SignupContainer = ({ children = null }) => {
  const { classes } = usePageContainerStyles();
  const { progressLabel, step, buttons, progress } = useSignupStepProgress();
  const { signup } = useTranslation();
  const theme = useTheme();

  // Enable temp storage of user login info between the first two create/confirm steps
  const [loginInfo, setLoginInfo] = React.useState(null);

  const finalRedirectTo = localStorage.getItem('redirectAfterLogin') || PAGE_PATHS.DASHBOARD;

  return (
    <Grid direction="column" container data-testid="signup-container">
      <LoginNavbar data-testid="login-navbar" />
      <Grid
        direction="column"
        container
        alignItems="center"
        item
        xs={12}
        className="App-header"
      >
        <Paper
          id="main"
          variant="gradient"
          component="main"
          sx={{
            mt: '4.5rem',
            mb: '3rem',
            [theme.breakpoints.down('md')]: {
              width: '100vw',
              minHeight: '100vh',
              height: '100%',
              margin: '0',
            },
          }}
          effect={progress === 100 ? 'celebrate' : ''}
        >
          <Grid
            container
            direction="column"
            sx={{
              height: '100%',
              width: '100%',
              gap: '2em',
              textAlign: 'center',
              minHeight: '65vh',
              flexWrap: 'nowrap',
              '& > .MuiGrid-root': {
                width: '100%',
                '& img': {
                  maxWidth: '100%',
                },
              },
              [theme.breakpoints.down('md')]: {
                gap: '1em',
                paddingTop: '3rem',
                paddingBottom: '1rem',
              },
            }}
          >
            <Grid
              item
              sx={{
                flex: '0 0 auto',
                display: 'flex',
                padding: '0 3em',
                [theme.breakpoints.down('md')]: {
                  padding: '0',
                },
              }}
            >
              <Typography sx={{ paddingRight: '.5em' }} data-testid="progress">
                {progressLabel}
              </Typography>{' '}
              {/* to add to progress component */}
              <Box sx={{ flex: '1 0 auto' }}>
                <LinearProgress name="progress" value={progress} />
              </Box>
            </Grid>

            <Grid
              className={clsx(classes.signUpContainer)}
              sx={{
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingBottom: '2rem',
              }}
            >
              {children ? (
                children
              ) : (
                <Outlet context={[loginInfo, setLoginInfo]} />
              )}
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flex: '0 0 auto',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1em',
                [theme.breakpoints.down('sm')]: {
                  paddingBottom: '1rem',
                },
              }}
            >
              {/* Temp placeholder(s) for links and submit button components! */}
              <SignupPrevNextLink
                navToStep={step - 1}
                variant="outlined"
                backIcon
                dark
              >
                {signup[buttons?.back]}
              </SignupPrevNextLink>
              <SignupPrevNextLink
                navToStep={step + 1}
                formId={buttons?.formId}
                variant="contained"
                nextIcon={
                  buttons?.next !== 'buttonLetsGo' &&
                  buttons?.next !== 'buttonSignup' &&
                  buttons?.next !== 'buttonComplete'
                }
              >
                {signup[buttons?.next]}
              </SignupPrevNextLink>
              {buttons?.skip ? (
                <Box sx={{ flex: '1 0 100%' }}>
                  <Typography variant="p">{signup.skipOnboardText} </Typography>
                  <Typography
                    variant="p"
                    component={Link}
                    to={finalRedirectTo}
                    onClick={() => {
                      localStorage.removeItem('redirectAfterLogin');
                    }}
                    fontWeight={700}
                  >
                    {signup.skipOnboardLink}
                  </Typography>
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

SignupContainer.propTypes = {
  ...BaseComponent,
};
