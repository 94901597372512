import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Paper, Typography, Button } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useGetAllActions } from '../../hooks/use-get-all-actions';
import { useAvatar } from '../../hooks/use-get-avatar.hook';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { getLogActionPath } from '../../constants/log-action-paths';
import { useDashboardData } from './DashboardProvider';
import useTranslation from '../customHooks/translations';


ChartJS.register(ArcElement, Tooltip, Legend);

const actionProps = [
  {
    id: 1,
    color: '#B0F36D',
    landingUrl: 'https://www.commit2act.org/plant-based-eating',
  },
  {
    id: 2,
    color: '#FF9CD5',
    landingUrl: 'https://www.commit2act.org/sustainable-transportation',
  },
  {
    id: 3,
    color: '#78F1F1',
    landingUrl: 'https://www.commit2act.org/avoid-plastic',
  },
  {
    id: 5,
    color: '#9EF2BF',
    landingUrl: 'https://www.commit2act.org/plant-a-garden',
  },
  {
    id: 11,
    color: '#FFAC5E',
    landingUrl: 'https://www.commit2act.org/avoid-fast-fashion',
  },
  {
    id: 12,
    color: '#FFE871',
    landingUrl: 'https://www.commit2act.org/wash-in-cold-water',
  },
  {
    id: 13,
    color: '#c276ff',
    landingUrl: 'https://ocean.org/action/lead-a-shoreline-cleanup/',
  },
];

const ActionsTile = () => {

  // Ref to the top app
  const topRef = React.useRef(null);

  // Define the handleClick function
  const handleClick = () => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  
  const theme = useTheme();
  const translation = useTranslation();
  // Get all Actions
  const { availableActions, loading } = useGetAllActions();
  
  // Users Actions
  const { actionsObject } = useDashboardData();
  const {weekly_unvalidated, weekly_number_of_actions} = actionsObject;

  const sortedActions = actionsObject 
  ? Object.values(actionsObject.actions_by_id).sort((a, b) => b.number - a.number)
  : [];
  
  // Avatar from All actions
  const { avatar } = useAvatar();
  
  // Get the IDs of the actions that are already in the actions map
  const existingActionIds = actionsObject && actionsObject.actions_by_id 
  ? Object.values(actionsObject?.actions_by_id).map(action => action.id)
  : [];

  // Filter the allActions array to only include actions that are not already in the actions map
  const remainingActions = availableActions.filter(action => !existingActionIds.includes(action.id));
  
  const getActionIcon = (id) => {
    const actionObject = avatar.actions.find((action) => action.id === id);
    return actionObject?.icon ? actionObject.icon : avatar.imageUrl;
  };

  const getActionColor = (id) => {
    const actionColor = actionProps.find((color) => color.id === id);
    if (actionColor?.color) return actionColor.color;
    return typeof id === 'number'
      ? actionProps[id % actionProps.length].color
      : actionProps[0].color;
  };

  const getActionLandingURL = (id) => {
    const actionURL = actionProps.find((urlObj) => urlObj.id === id);
    if (actionURL?.landingUrl) return actionURL.landingUrl;
    return typeof id === 'number'
      ? actionProps[id % actionProps.length].landingUrl
      : actionProps[0].landingUrl;
  };

  const actionsArray = actionsObject && actionsObject.actions_by_id 
  ? Object.values(actionsObject.actions_by_id).map((action, index) => ({
      ...action,
      color: getActionColor(action.id),
      key: index,
    }))
  : [];

  const data = React.useMemo(() => {
    
    if (loading) {
      return {
        labels: [],
        datasets: [
          {
            label: 'My Actions',
            data: [],
            backgroundColor: [],
            borderColor: [theme.palette.common.dark],
            borderWidth: 2,
          },
        ],
      };
    }

    return {
      labels: actionsArray.map((action) => `${action.action_name} : ${action.number} `),
      datasets: [
        {
          label: 'My Actions',
          data: actionsArray.map((action) => action.number),
          backgroundColor: actionsArray.map((action) => action.color),
          borderColor: [theme.palette.common.dark],
          borderWidth: 2,
        },
      ],
    };
  }, [loading, theme, actionsArray]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { 
        enabled: true, 
        displayColors: true,
        backgroundColor: actionsArray.map((action) => action.color),
        bodyFont: { weight: 'bold', size: 16 },
        bodyColor: theme.palette.common.light, 
        bodyStrokeWidth: 5,
        xAlign: "left",
        callbacks: {
          label: function(context) {
            return context.label;
          }
        },
      },
    },
  }

  if (loading && actionsArray.length === 0) {
    return <CircularProgress />;
  }

  return (
    <>
    <Box sx={{
      width: '100%',
      display: {xs: 'block', md: 'flex'}, 
      justifyContent: 'space-between',

    }}> 
      <Box>
        <Paper sx={{
          flex: '1', 
          alignSelf: 'start',
          margin: '2em', 
          borderRadius: {xs:'1em', md: '99em'}, 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center', 
          justifyContent: 'center', 
          textAlign: 'center',
          padding: '1.2em 1.5em 1.8em 1.5em',
          aspectRatio: {md:'1/1'},
          maxWidth: {md: '80px'},
          background: theme.palette.secondary.main,
          boxShadow: '9px 9px 47px rgba(117, 151, 5, 0.26)',
          border: '1px solid' + theme.palette.primary.main,
          }}>
              <Typography variant="h2" sx={{fontWeight: 'bold'}}>{weekly_number_of_actions}</Typography> 
              <Typography variant="small">{translation['dashboardPage'].actionsThisWeek}</Typography>
        </Paper>
        <Box>
          <Typography variant="h3" sx={{fontWeight: 'bold', mt: '3rem', mb: '.5rem'}}>
          {translation.formatString(translation['dashboardPage'].weeklyActions, weekly_unvalidated || 0)}
          </Typography>
          <Typography variant="small">{translation['dashboardPage'].awaitingValidation}</Typography>
        </Box>
      </Box>
      <Box sx={{flex: '1', position: 'relative', maxWidth: '282px'}}>
        <Box sx={{position: 'relative', zIndex: '2'}}>
          <Doughnut data={data} aria-label="Doughnut chart of actions" options={options}/>
        </Box>
        <Box sx={{ 
          position: 'absolute',
          zIndex: '0',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '99em',
          width: '50%',
          height: '50%',
          display: 'flex',
          alignItems: 'center',
        }}>
          <Typography variant="body" sx={{textAlign: 'center', padding: '2em'}}>{translation['dashboardPage'].actionsBreakdown}</Typography>
        </Box>
      </Box>
    </Box>

    {/* Action Tiles Grid */}
    <Box sx={{
      width: '100%',
      paddingTop: '2em',
      display: 'grid',
      gridTemplateColumns: {md: '1fr 1fr', xsm: '1fr'},
      gridAutoRows: '1fr',
      gap: '2em',
    }}> 

    {/* Users Action List */}
    {sortedActions.map((action) => (
      <Paper key={action.id} sx={{
        borderRadius: '1.1em',
        textAlign: 'center',
        flex: '1',
        padding: '1em 1.5em 1.5em 1.5em',
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: getActionColor(action.id),
        color: theme.palette.common.dark,
      }}>
        <Box
          component="img"
          src={action.avatar}
          alt="action avatar"
          sx={{
            maxHeight: '7em',
            maxWidth: '8em',
            margin: '0 auto',
            padding: '1em',
          }}
        />
        <Typography sx={{
          fontWeight: '600', 
          textTransform: 'uppercase',
          color: theme.palette.common.dark,
          mb: '.5em',
        }}>
          {action.action_name}
        </Typography>
        <Typography variant="body" sx={{lineHeight: '1.5em', paddingBottom: '1em',}}>
          {action.co2_saved !== 0 && (
            <>
              {translation.co2SavedColon} <strong>{action.co2_saved}</strong><br />
            </>
          )}
          {translation['dashboardPage'].actionsTakenColon} <strong>{action.number}</strong><br />
          {translation['dashboardPage'].pointsEarnedColon} <strong>{action.points_earned}</strong>
        </Typography>
        <Button 
          variant="contained"
          component={Link}
          to={getLogActionPath('ENTER_DETAILS', action.id)}
          onClick={handleClick}
          sx={{
            background: theme.palette.common.dark,
            '&:hover': {
              boxShadow: 'inset 0px 4px 11px #000000',
              background: theme.palette.common.dark,
            }
          }}
        >
          {translation['dashboardPage'].logNow}
        </Button>
      </Paper>
      ))}
      
      {/* empty action tile */}
      {remainingActions.map((action) => (
      <Paper key={action.id} sx={{
        borderRadius: '1.1em',
        textAlign: 'center',
        flex: '1',
        padding: '1em 1.5em 1.5em 1.5em',
        display: 'inline-flex',
        flexDirection: 'column',
        gap: '1em',
        border: `2px solid ${getActionColor(action.id)}`,
        color: theme.palette.common.light,
      }}>
        <Box
          component="img"
          src={getActionIcon(action.id)}
          alt=""
          sx={{
            maxHeight: '7em',
            maxWidth: '8em',
            margin: '0 auto',
            padding: '1em',
          }}
        />
        <Typography sx={{
          fontWeight: '600', 
          textTransform: 'uppercase',
          color: theme.palette.common.light,
        }}>
          {action.name}
        </Typography>
        <Typography variant="body" sx={{
          lineHeight: '1.5em',
          paddingBottom: '1em',
          '& a': {
              color: theme.palette.common.light,
              fontWeight: '600',
            }
          }}>
          {translation['dashboardPage'].emptyAction1}
          <strong>0</strong> {translation['dashboardPage'].emptyAction2} 
          <a href={getActionLandingURL(action.id)} target="_blank" rel="noreferrer"
          >{translation['dashboardPage'].emptyAction3}
          </a>?
        </Typography>
        <Button 
          variant="contained"
          component={Link}
          onClick={handleClick}
          to={getLogActionPath('ENTER_DETAILS', action.id)}
          sx={{
            background: theme.palette.common.dark,
            border: `1px solid ${getActionColor(action.id)}`,
            '&:hover': {
              boxShadow: 'inset 0px 4px 11px #000000',
              background: theme.palette.common.dark,
            }
          }}
        >
          {translation['dashboardPage'].logNow}
        </Button>
      </Paper>
      ))}
    </Box>
    </>
  )
};

export default ActionsTile; 