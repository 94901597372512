const SIGNUP_FLOW_TYPE = {
  SIMPLE: 'simple',
  ONBOARD: 'onboard',
};

export const getSignupFlowType = () =>
  process.env.REACT_APP_SIGNUP_FLOW || SIGNUP_FLOW_TYPE.SIMPLE;

export const isSimpleSignupFlow = () =>
  getSignupFlowType() === SIGNUP_FLOW_TYPE.SIMPLE;


export const getUserEmail = (user) => {
  let email = user.attributes.email;
  if (!email) {
    const identities = JSON.parse(user.attributes.identities);
    if (identities[0].providerType === "SignInWithApple") {
      email = user.attributes.preferred_username;
    }
  }

  return email;
}