export const getSimpleUserActionFromResponse = (action) => ({
  actionId: action?.action_id || null,
  co2Saved: action?.g_co2_saved || 0,
  dateOfAction: action?.date_of_action || null,
  firstQuizAnswerCorrect: action?.first_quiz_answer_correct || false,
  id: action?.sa_id || null,
  isImageExplicit: action?.is_image_explicit || false,
  isRejected: action?.is_rejected || false,
  isValidated: action?.is_validated || false,
  pointsEarned: action?.points_earned || 0,
  quizAnswered: action?.quiz_answered || false,
  quizId: action?.quiz_id || null,
  timeSubmitted: action?.time_submitted || null,
  userId: action?.user_id || null,
  userImage: action?.submitted_image || null,
});
