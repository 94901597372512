import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { getActionItemsForAction } from '../graphql/queries';
import { getSimpleActionItemFromResponse } from '../utils/get-simple-action-item-from-response';
import { useContentTranslationsContext } from '../components/contexts/ContentTranslationsContext';
import { useLanguageContext } from '../components/contexts/LanguageContext';

export const useGetItemsForAction = () => {
  const params = useParams();
  const paramsActionId = params?.actionId || null;
  const { language } = useLanguageContext();
  const { contentTranslations } = useContentTranslationsContext();
  const [actionId, setActionId] = useState(null);
  const [actionItems, setActionItems] = useState([]);
  const [hasNoItems, setHasNoItems] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const translateActions = (actions, languageCode) => {
    const translations = contentTranslations?.find(
      (translation) => translation.langCode === languageCode
    );
    if (!translations || !translations.translationJSON?.actions) {
      return actions;
    }
    return actions.map((action) => {
      const translatedAction = translations.translationJSON.actions.find(
        (translation) => translation.action_id === action.action_id
      );
      return translatedAction ? { ...action, ...translatedAction } : action;
    });
  };

  const formatUpdatedActions = (translatedActions) => {
    return translatedActions.reduce((acc, item) => {
      const updateActionItems = item.action_items.map((subItem) => ({
        id: subItem.action_id,
        name: subItem.item_name,
        description: subItem.item_description,
      }));
      updateActionItems.forEach((subItem) => {
        if (!acc.some((existingItem) => existingItem.id === subItem.id && existingItem.name === subItem.name)) {
          acc.push(subItem);
        }
      });
      return acc;
    }, []);
  };

  const fetchItemsForAction = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await API.graphql({
        query: getActionItemsForAction,
        variables: { action_id: paramsActionId },
      });

      const items = response?.data?.getActionItemsForAction || [];
      setActionId(paramsActionId);
      setHasNoItems(items.length === 0);
      setLoading(false);

      let updatedItems;
      if (language === 'fr') {
        const translatedActions = translateActions(items, 'fr');
        updatedItems = formatUpdatedActions(translatedActions);
      } else if (language === 'es') {
        const translatedActions = translateActions(items, 'es');
        updatedItems = formatUpdatedActions(translatedActions);
      } else {
        updatedItems = items.map(getSimpleActionItemFromResponse);
      }

      setActionItems(updatedItems);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, [paramsActionId, language, contentTranslations]);

  useEffect(() => {
    if (paramsActionId) {
      setActionItems([]);
      fetchItemsForAction();
    }
  }, [paramsActionId, fetchItemsForAction]);

  useEffect(() => {
    if (paramsActionId && paramsActionId !== actionId) {
      setHasNoItems(false);
      setActionItems([]);
    }
  }, [paramsActionId, actionId]);

  return {
    actionItems,
    hasNoItems,
    loading,
    error,
  };
};
