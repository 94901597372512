import React from 'react';
import { Box } from '@mui/material';
import ActionFactImage from '../logAction/ActionFactImage';
import { useDashboardData } from './DashboardProvider';

const LearningBox = () => {
  const { learning } = useDashboardData();

  return <Box><ActionFactImage /></Box>;
};

export default LearningBox;