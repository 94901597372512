export const familiarityOptions = [
  {
    id: '1',
    title: 'Very experienced',
    value: 'Very experienced',
  },
  {
    id: '2',
    title: 'Somewhat experienced',
    value: 'Somewhat experienced',
  },
  {
    id: '3',
    title: 'Just getting started',
    value: 'Just getting started',
  },
];
