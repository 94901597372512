import { API } from 'aws-amplify';
import { useGetCurrentUserProfile } from './use-get-current-user-profile.hook';
import { useUserInfoContext } from './use-user-info-context';
import { getAllSubmittedActionsForUser, getAllUsers } from '../graphql/queries';
import { useEffect, useState } from 'react';
import { avatarOptions } from '../constants/avatar-options';
import useTranslation from '../components/customHooks/translations';

const defaultStats = {
  total_points: 0,
  weekly_points: 0,
  number_of_actions: 0,
  total_co2: 0,
  weekly_co2: 0,
  streak: 0,
}
const defaultActions = {
  actions_by_id: []
}
const defaultActionById = {
  action_name: '', 
  avatar: null,   
  co2_saved: 0,
  points_earned: 0,
  number: 0
}
const defaultRankings = {
  user: null,
  all_users: [],
}
const defaultImpact = {
  weekly_co2: 0,
  actions_by_month: []
}
const defaultActionByDay = {
  date: null,
  total_co2_saved: 0
}

function reduceActionsById(actions, avatar){
  if(!actions.length > 0) return [];
  return actions.reduce((acc, action) => {
    if (!action.is_rejected && !acc[action.action_id]) {
      acc[action.action_id] = {
        ...defaultActionById,
        action_name: action.action_name, 
        avatar: avatar.actions.find(act => act.id === action.action_id)?.icon || avatar.imageUrl,
        id: action.action_id,
      };
    }
    if(!action.is_rejected){
      acc[action.action_id].co2_saved += action.g_co2_saved;
      acc[action.action_id].points_earned += action.points_earned;
      acc[action.action_id].number += 1;
    }
    return acc;
  }, {});
}
function countActions(actions){
  let oneWeekAgo = new Date()
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  let countValidatedWeek = 0;
  let countNotValidatedWeek = 0;
  let countAll = 0;
  actions.forEach(action => {
    if(!action.is_rejected){
      countAll++;
      const actionDate = new Date(action.time_submitted);
      if (actionDate >= oneWeekAgo) {
        if (action.is_validated) {
          countValidatedWeek++;
        } else {
          countNotValidatedWeek++;
        }
      }
    }
  });

  return {
    weekly_validated: countValidatedWeek,
    weekly_unvalidated: countNotValidatedWeek,
    number_of_actions: countAll
  };

}
function reduceActionsByDay(actions){
  const result = actions.reduce((acc, action) => {
    const date = action.time_submitted.split('T')[0];
    if(!action.is_rejected) {
      if (!acc[date]) {
        acc[date] = {
          ...defaultActionByDay,
          date,
        };
      }
      acc[date].total_co2_saved += action.g_co2_saved;
    }
    return acc;
  }, {});

  return Object.values(result);
}
function reduceActionsByMonth(actions){
  const currentYear = new Date().getFullYear();
  let monthValues = new Array(12).fill(0);
  actions.forEach(action => {
    if(!action.is_rejected) {
      const date = new Date(action.time_submitted);
      const year = date.getFullYear();
      const month = date.getMonth(); 
  
      if (year === currentYear) {
        monthValues[month] += action.g_co2_saved;
      }
    }
  });

  return monthValues;
}
function findLongestStreak(actions){
  const sortedDays = actions.sort((a, b) => new Date(a.date) - new Date(b.date));

  let maxStreak = 0;
  let currentStreak = 1;
  let previousDate = new Date(sortedDays[0].date);

  for (let i = 1; i < sortedDays.length; i++) {
    const currentDate = new Date(sortedDays[i].date);
    const differenceInDays = (currentDate - previousDate) / (1000 * 3600 * 24);
    if (differenceInDays === 1) {
      currentStreak++;
    } else {
      currentStreak = 1;
    }
    if (currentStreak > maxStreak) {
      maxStreak = currentStreak;
    }
    previousDate = currentDate;
  }

  return maxStreak;
}
function findCurrentStreak(actions) {
  if (actions.length === 0) return 0;

  const sortedDays = actions.sort((a, b) => new Date(a.date) - new Date(b.date));
  
  let currentStreak = 1;
  let previousDate = new Date(sortedDays[0].date);

  for (let i = 1; i < sortedDays.length; i++) {
    const currentDate = new Date(sortedDays[i].date);
    const differenceInDays = (currentDate - previousDate) / (1000 * 3600 * 24);
    if (differenceInDays === 1) {
      currentStreak++;
    } else if (differenceInDays > 1) {
      currentStreak = 1;
    }
    previousDate = currentDate;
  }

  const today = new Date();
  const mostRecentActionDate = new Date(sortedDays[sortedDays.length - 1].date);
  const differenceToToday = (today - mostRecentActionDate) / (1000 * 3600 * 24);

  if (differenceToToday > 1) {
    currentStreak = 0;
  }

  return currentStreak;
}
export const useCurrentUserDashboard = () => {

  const { user } = useUserInfoContext();
  const { userProfile } = useGetCurrentUserProfile();

  const [actions, setActions] = useState(defaultActions);
  const [stats, setStats] = useState(defaultStats);
  const [rankings, setRankings] = useState(defaultRankings);
  const [impact, setImpact] = useState(defaultImpact);
  
  useEffect(() => {
    async function getApiData(){
      if(user){
        const [actionRes, usersRes] = await Promise.all([
          API.graphql({ 
            query: getAllSubmittedActionsForUser,
            variables: { user_id: user.user_id },
          }),
          API.graphql({ query: getAllUsers }),
        ])
        const allActions = actionRes?.data?.getAllSubmittedActionsForUser;
        const allUsers = usersRes?.data?.getAllUsers;
        if(allActions && allActions.length > 0) {
          const avatar = avatarOptions.find(avatar => avatar.id === userProfile?.avatar) || avatarOptions[0];
          const { weekly_validated, weekly_unvalidated, number_of_actions} = countActions(allActions);
          const actions_by_id = reduceActionsById(allActions, avatar);
          setActions(prev => 
            ({
              ...prev,
              weekly_validated,
              weekly_unvalidated,
              weekly_number_of_actions: weekly_validated + weekly_unvalidated,
              actions_by_id
            })
          );
          const actions_by_month = reduceActionsByMonth(allActions); 
          setImpact(prev => 
            ({
              ...prev,
              weekly_co2: user.weekly_co2,
              actions_by_month
            })
          )
          const actions_by_day = reduceActionsByDay(allActions); 
          const longest_streak = findLongestStreak(actions_by_day);
          const current_streak = findCurrentStreak(actions_by_day);
          
          setStats(prev => ({
              ...prev,
              total_points: user.total_points,
              weekly_points: user.weekly_points,
              number_of_actions,
              total_co2: user.total_co2,
              weekly_co2: user.weekly_co2,
              streak: current_streak,
              longest_streak
            })
          )
        }
        if(allUsers && allUsers.length) {
          const sorted_users = allUsers.sort((a, b) => b.total_points - a.total_points);
          const rank = sorted_users.findIndex(x => user.user_id === x.user_id) + 1;
          const current_user = {
            ...user,
            rank
          }
          setRankings(prev => 
            ({
              ...prev,
              user: current_user,
              all_users: sorted_users
            })
          )
        }
        
        
      }    
    }

    getApiData();
  }, [user, userProfile?.avatar]);

  const translation = useTranslation();
  const greetingText = userProfile?.name
    ? translation.formatString(translation.welcomeName, userProfile.name)
    : `${translation.welcome}!`;

  return {
    greetingText,

    // These will all be arrays or objects of proper data
    actionsObject: actions,
    actions: '(actions)',
    impactObject: impact,
    impact: '(impact)',
    learning: '(learning)',
    notifications: '(notifications)',
    rankingsObject: rankings,
    rankings: '(rankings)',
    statsObject: stats,
    stats: '(stats)'
  };
};
