import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useVerifyUser } from '../../../hooks/use-verify-user.hook';
import { useGetCurrentUser } from '../../../hooks/use-get-current-user.hook';
import useTranslation from '../../../components/customHooks/translations';
import { initialSignupData } from '../01InfoPrimary/InfoPrimary';
import { SIGNUP_NAMES, SIGNUP_PATHS } from '../../../constants/signup-paths';
import TextInput from '../../../components/Inputs/TextInput';
import { LoadingSpinner } from '../LoadingSpinner';
import { ResponseError } from '../ResponseError';

export const UserConfirm = () => {
  const navigate = useNavigate();
  const translation = useTranslation();

  const [loginInfo, setLoginInfo] = useOutletContext();
  const [signupData, setSignupData] = React.useState(initialSignupData);
  const { verifyUser, isLoading, success, error } = useVerifyUser();
  const { currentUser } = useGetCurrentUser();

  function handleChange(e) {
    e.persist();
    setSignupData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  const onSubmitForm = (event) => {
    event.preventDefault();

    const { confirmation_code } = signupData;
    const email = loginInfo?.email || signupData.email;
    const password = loginInfo?.password || signupData.password;

    if (email === '' || password === '' || confirmation_code === '') return;

    verifyUser({ email, password, confirmation_code });
  };

  React.useEffect(() => {
    if (!isLoading && success && currentUser !== undefined) {
      setLoginInfo(null);
      navigate(SIGNUP_PATHS.INFO_SECONDARY);
    }
  }, [isLoading, navigate, success, currentUser, setLoginInfo]);

  return (
    <Box
      data-testid="user-confirm"
      sx={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}
    >
      <Box>
        <Box
          component="img"
          alt=""
          src="../../assets/images/avatars/LetsGoWhale.svg"
          mb="1em"
        />
      </Box>
      <Typography variant="h1">{translation.confirmEmail}</Typography>
      <Box>
        <Typography variant="p" display="block">
          {translation.checkEmailForConfirmationCode}
        </Typography>
      </Box>
      <ResponseError error={error} />
      <Box
        id={`form-${SIGNUP_NAMES.USER_CONFIRM}`}
        component="form"
        onSubmit={onSubmitForm}
        sx={{
          maxWidth: '25rem',
          margin: '0 auto',
          '& > .MuiBox-root': { margin: '1em 0' },
        }}
      >
        {!loginInfo ? (
          <>
            <TextInput
              id="email"
              name="email"
              label={translation.email}
              inputType="email"
              variant="outlined"
              required
              value={signupData.email}
              onChange={handleChange}
              disabled={isLoading}
              placeholder={translation.enterYourEmail}
              sx={{ marginBottom: '1em' }}
            />
            <TextInput
              required
              label={translation.password}
              name="password"
              id="password"
              placeholder={translation.enterYourPassword}
              autoComplete="new-password"
              value={signupData.password}
              onChange={handleChange}
              disabled={isLoading}
              inputType="password"
              sx={{ marginBottom: '1em' }}
            />
          </>
        ) : null}
        <TextInput
          fullWidth
          required
          label="Confirmation Code"
          placeholder="Enter your code"
          name="confirmation_code"
          inputType="text"
          autoComplete="off"
          value={signupData.confirmation_code}
          onChange={handleChange}
          disabled={isLoading}
        />
      </Box>
      <Typography variant="body1" component="p">
        {translation.signup.didNotReceiveConfirmation}
        <Typography
          component="a"
          target="_blank"
          href="https://www.commit2act.org/contact"
        >
          {translation.signup.contactCommit2Act}
        </Typography>
        .
      </Typography>
      <LoadingSpinner loading={isLoading} sx={{ marginBottom: '1rem' }}>
        {translation.signup.loadingConfirmation}
      </LoadingSpinner>
    </Box>
  );
};
