import * as React from 'react';
import useTranslation from './customHooks/translations';
import { useActiveStepContext } from '../hooks/use-active-step-context';
import { TOTAL_LOG_STEPS } from '../constants/log-steps';
import { LinearProgress } from './LinearProgress/LinearProgress';

const stepsToIgnore = [0, 5];

export const StepCounter = () => {
  const { signup, formatString } = useTranslation();
  const { activeStep } = useActiveStepContext();

  if (stepsToIgnore.includes(activeStep)) return null;

  const progress = Math.ceil(((activeStep + 1) / TOTAL_LOG_STEPS) * 100);

  return (
    <LinearProgress
      value={progress}
      name={formatString(signup.progressLabel, progress)}
      sx={{ background: '#434343', height: '0.75rem', mb: '3.5rem' }}
    />
  );
};
