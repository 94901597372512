import React from 'react';
import { FormControl, FormGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { MultiSelectTile } from './MultiSelectTile';

export const MultiSelectTileGroup = ({
  selectionOptions,
  ariaLabel,
  name,
  selectedItems,
  setSelectedItems,
}) => {
  const toggleItemSelection = (itemId) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : [...prevSelectedItems, itemId]
    );
  };

  return (
    <FormControl>
      <FormGroup
        aria-label={ariaLabel}
        sx={{ justifyContent: 'center' }}
        name={name}
        row
      >
        {selectionOptions.map((item) => (
          <MultiSelectTile
            id={item.id}
            key={item.id}
            value={item.id}
            label={item.label}
            loading={item.loading}
            imageUrl={item.imageUrl}
            selectedUrl={item.selectedUrl}
            checked={selectedItems?.includes(item.id)}
            onToggle={toggleItemSelection}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

MultiSelectTileGroup.propTypes = {
  selectionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      imageUrl: PropTypes.string,
      selectedUrl: PropTypes.string,
      loading: PropTypes.bool,
    })
  ),
};
