import React from 'react';
import { Box } from '@mui/material';
import { Button } from '../Button/Button';

const ActionButtons = ({
  backOnClick = () => {},
  backText = null,
  forwardOnClick,
  forwardText,
  forwardProps = {},
  backProps = {},
  children,
}) => (
  <Box maxWidth="50rem" margin="1em auto">
    {children}
    <Box
      sx={{
        display: 'flex',
        gap: '1em',
        justifyContent: 'center',
        marginY: '3em',
      }}
    >
      {backText && (
        <Button
          onClick={backOnClick}
          variant="outlined"
          sx={{
            minWidth: '20vw',
            fontSize: '1.75rem',
            border: 'solid 1px #fff',
          }}
          {...backProps}
        >
          {backText}
        </Button>
      )}
      <Button
        onClick={forwardOnClick}
        variant="contained"
        sx={{
          minWidth: '20vw',
          fontSize: '1.75rem',
        }}
        {...forwardProps}
      >
        {forwardText}
      </Button>
    </Box>
  </Box>
);

export default ActionButtons;
