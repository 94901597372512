import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { BaseComponent } from '../../../prop-types/component';
import useTranslation from '../../../components/customHooks/translations';
import { useAvatar } from '../../../hooks/use-get-avatar.hook';

export const ActionsImpact = () => {
  const { signup } = useTranslation();
  const { avatar, isLoading } = useAvatar();

  return (
    <Box data-testid="actions-impact" sx={{ maxWidth: '100%' }}>
      {!isLoading && (
        <Box
          component="img"
          alt=""
          src={avatar.flag || avatar.imageUrl}
          sx={{
            mb: '1em',
            width: '13%',
            height: '13%',
            minWidth: '10em',
            maxWidth: '100%',
          }}
        />
      )}
      <Typography variant="h1">{signup.actionsImpactHeading}</Typography>
      {/* to calculate the impact of the actions 
      <Typography
        variant="p"
        component="p"
        sx={{
          lineHeight: '1.5',
          maxWidth: '600px',
          margin: '0 auto',
          paddingTop: '1.25em',
        }}
      >
        {signup.actionsImpactSubheading1a}
        [some value]
        {signup.actionsImpactSubheading1b}
      </Typography>*/}
      <Typography
        variant="p"
        component="p"
        sx={{
          lineHeight: '1.5',
          maxWidth: '600px',
          margin: '0 auto',
          paddingTop: '1.25em',
        }}
      >
        {signup.actionsImpactSubheading2}
      </Typography>
      <Typography
        variant="p"
        component="p"
        sx={{
          lineHeight: '1.5',
          maxWidth: '600px',
          margin: '0 auto',
          paddingTop: '1.25em',
        }}
      >
        {signup.actionsImpactSubheading3}
      </Typography>
    </Box>
  );
};

ActionsImpact.propTypes = {
  ...BaseComponent,
};
