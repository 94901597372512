import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { BaseComponent } from '../../../prop-types/component';
import useTranslation from '../../../components/customHooks/translations';

export const OnboardStart = () => {
  const { signup } = useTranslation();

  return (
    <Box data-testid="onboard-start">
      <Box
        component="img"
        alt=""
        src="../../assets/images/avatars/welcome-whale.svg"
        mb="1em"
      />
      <Typography variant="h1">{signup.onboardStartHeading}</Typography>
      <Typography variant="p" component="p" mt="1em">
        {signup.onboardStartSubHeading}
      </Typography>
    </Box>
  );
};

OnboardStart.propTypes = {
  ...BaseComponent,
};
