import * as React from 'react';
import {
  Box,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BaseComponent } from '../../../prop-types/component';
import useTranslation from '../../../components/customHooks/translations';
import { useSignupIntendUse } from '../../../hooks/use-signup-intend-use.hook';
import { SIGNUP_NAMES, SIGNUP_PATHS } from '../../../constants/signup-paths';
import { LoadingSpinner } from '../LoadingSpinner';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { visuallyHidden } from '@mui/utils';
import { useAvatar } from '../../../hooks/use-get-avatar.hook';

export const UsageIntend = () => {
  const navigate = useNavigate();

  const { signup } = useTranslation();
  const { avatar, isLoading } = useAvatar();

  const { intendUseOptions, intendUse, setIntendUse, sendIntendUseRequest } =
    useSignupIntendUse();
  const [loading, setLoading] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);

  React.useEffect(() => {
    setIntendUse(selectedItems);
  }, [selectedItems, setIntendUse]);

  const handleSelection = (itemId) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : [...prevSelectedItems, itemId]
    );
  };

  const onSubmitForm = async (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);

      try {
        await sendIntendUseRequest();
        setLoading(false);
        navigate(SIGNUP_PATHS.USAGE_INSTRUCT);
      } catch (e) {
        setLoading(false);
        console.log('Error: ', e);
      }
    }
  };
  return (
    <Box data-testid="usage-intend" sx={{ maxWidth: '100%' }}>
      {!isLoading && (
        <Box
          component="img"
          alt=""
          src={avatar.phone || avatar.imageUrl}
          sx={{
            mb: '1em',
            width: '10%',
            height: '10%',
            minWidth: '8em',
            maxWidth: '100%',
          }}
        />
      )}
      <Typography variant="h1">{signup.usageIntendHeading}</Typography>
      <Box
        id={`form-${SIGNUP_NAMES.USAGE_INTEND}`}
        component="form"
        onSubmit={onSubmitForm}
        sx={{
          maxWidth: '35rem',
          margin: '2em auto',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '1rem',
        }}
      >
        <FormGroup
          aria-labelledby="usage-intend-label"
          name="usage-intend"
          value={intendUse}
          onChange={(e) => handleSelection(e.target.value)}
          sx={{ alignItems: 'center', gap: '0.8em', paddingRight: '2em' }}
        >
          {intendUseOptions.map((option) => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              variant={'lightPill'}
              control={<CheckCircleIcon sx={visuallyHidden} />}
              checked={selectedItems.includes(option.id)}
              className={selectedItems.includes(option.id) ? 'checked' : ''}
              disabled={loading}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography display="inline" sx={{ textAlign: 'left' }}>
                    {option.label}
                  </Typography>
                  <Checkbox
                    value={option.id}
                    icon={<CheckCircleIcon sx={visuallyHidden} />}
                    checkedIcon={<CheckCircleIcon sx={{ color: 'black' }} />}
                    checked={selectedItems.includes(option.id)}
                  />
                </Box>
              }
              sx={{
                width: '100%',
                '& .MuiFormControlLabel-label.Mui-disabled': {
                  color: '#555',
                },
              }}
            />
          ))}
        </FormGroup>
      </Box>
      <LoadingSpinner loading={loading} />
    </Box>
  );
};

UsageIntend.propTypes = {
  ...BaseComponent,
};
