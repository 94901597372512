import { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { getSimpleUserFromAttributes } from '../utils/get-simple-user-from-attributes';

export const USER_AUTH_EVENTS = {
  SIGN_IN: 'signIn',
  SIGN_OUT: 'signOut',
};

export const useGetCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(undefined);

  useEffect(() => {
    // Naming the returned function from Hub.listen allows us to
    // cancel the listener once we're finished with it:
    // https://docs.amplify.aws/javascript/build-a-backend/utilities/hub/#stop-listening
    const hubAuthListener = Hub.listen('auth', ({ payload }) => {
      const { event, data } = payload;
      if (event === USER_AUTH_EVENTS.SIGN_IN) {
        const currentUserObject = getSimpleUserFromAttributes(data.attributes);
        setCurrentUser(currentUserObject);
        setIsUserLoggedIn(true);
      }
      if (event === USER_AUTH_EVENTS.SIGN_OUT) {
        setCurrentUser(undefined);
        setIsUserLoggedIn(false);
      }
    });

    return () => {
      // Cancel the listener when unloading our useEffect
      hubAuthListener();
    };
  });

  useEffect(() => {
    const getAuthenticatedUser = async () => {
      try {
        const { attributes } = await Auth.currentAuthenticatedUser();
        const currentUserObject = getSimpleUserFromAttributes(attributes);
        setCurrentUser(currentUserObject);
        setIsUserLoggedIn(true);
      } catch {
        setCurrentUser(undefined);
        setIsUserLoggedIn(false);
      }
    };

    // Ease the Auth request frequency by only checking user status
    // when we've got an undefined (or unknown) login status
    if (isUserLoggedIn === undefined) {
      getAuthenticatedUser();
    }
  }, [isUserLoggedIn]);

  return {
    currentUser,
    isUserLoggedIn,
    isUserAdmin: !!(isUserLoggedIn && currentUser?.isAdmin),
  };
};
