import React from 'react';
import GlobalLeaderboard from '../GlobalLeaderboard';
import { Typography, Paper, Box } from '@mui/material';

import theme from '../../themes';
import { useDashboardData } from './DashboardProvider';
import useTranslation from '../customHooks/translations';

const RankingsBox = () => {
  const { rankingsObject: rankings } = useDashboardData();
  const userRank = rankings.user?.rank; // Access the rank property of the user object
  const translation = useTranslation();
  return ( 
    <>
      <Paper sx={{
        borderRadius: '20px',
        background: 'linear-gradient(180deg, #00E8FF 0%, #26AAB7 78.82%)',
        padding: '1.4em 1em',
        textAlign: 'center',
        color: theme.palette.common.dark,
        lineHeight: '1.2em',
        marginBottom: '2em'
      }}>
        <Typography component="h3" sx={{fontWeight: '900', paddingBottom: '15px', fontSize: '1.67em', color: theme.palette.common.dark}}>
          {translation.rank} <br/>
          <strong>{userRank}</strong>
        </Typography>
        
      </Paper>
      <GlobalLeaderboard />
    </>
  )
};

export default RankingsBox;