import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ActionStyles = {
  0: { color: '#ffffff' },
  1: { color: '#8EDAAC' },
  2: { color: '#E661AE' },
  5: { color: '#8CBD5B' },
  3: { color: '#6FDDDD' },
  11: { color: '#F1A660' },
  12: { color: '#FFD467' },
};

export const ActiveStepContext = createContext();

export const ActiveStepProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedAction, setSelectedAction] = useState(null);
  const [actionStyle, setActionStyle] = useState(ActionStyles[0])




  return (
    <ActiveStepContext.Provider
      value={{
        activeStep,
        actionStyle,
        selectedAction,
        setActiveStep,
        setActionStyle,
        setSelectedAction,
      }}
    >
      {children}
    </ActiveStepContext.Provider>
  );
};

export const useActiveStepContext = () => {
  return useContext(ActiveStepContext);
};

ActiveStepProvider.propTypes = {
  children: PropTypes.node,
};
