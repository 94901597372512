import * as React from 'react';

export const LogoInstagram = (props) => (
  <svg
    width="43"
    height="41"
    viewBox="0 0 43 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.6596 10.1718C15.6025 10.1718 10.7167 14.7506 10.7167 20.4272C10.7167 26.1039 15.6025 30.6827 21.6596 30.6827C27.7168 30.6827 32.6025 26.1039 32.6025 20.4272C32.6025 14.7506 27.7168 10.1718 21.6596 10.1718ZM21.6596 27.0947C17.7453 27.0947 14.5453 24.1046 14.5453 20.4272C14.5453 16.7499 17.7358 13.7598 21.6596 13.7598C25.5834 13.7598 28.7739 16.7499 28.7739 20.4272C28.7739 24.1046 25.5739 27.0947 21.6596 27.0947ZM35.6025 9.75225C35.6025 11.0822 34.4597 12.1443 33.0501 12.1443C31.6311 12.1443 30.4977 11.0732 30.4977 9.75225C30.4977 8.43126 31.6406 7.36019 33.0501 7.36019C34.4597 7.36019 35.6025 8.43126 35.6025 9.75225ZM42.8501 12.18C42.6882 8.97572 41.9073 6.13739 39.4025 3.79888C36.9073 1.46038 33.8787 0.728485 30.4596 0.567824C26.9358 0.380387 16.3739 0.380387 12.8501 0.567824C9.44055 0.719559 6.41197 1.45146 3.9072 3.78996C1.40243 6.12846 0.631 8.9668 0.459571 12.1711C0.25957 15.4735 0.25957 25.372 0.459571 28.6745C0.621476 31.8788 1.40243 34.7171 3.9072 37.0556C6.41197 39.3941 9.43102 40.126 12.8501 40.2867C16.3739 40.4741 26.9358 40.4741 30.4596 40.2867C33.8787 40.1349 36.9073 39.403 39.4025 37.0556C41.8978 34.7171 42.6787 31.8788 42.8501 28.6745C43.0501 25.372 43.0501 15.4825 42.8501 12.18ZM38.2978 32.2179C37.5549 33.9674 36.1168 35.3151 34.2406 36.0202C31.4311 37.0645 24.7644 36.8235 21.6596 36.8235C18.5549 36.8235 11.8786 37.0556 9.07864 36.0202C7.21197 35.324 5.77387 33.9763 5.02149 32.2179C3.9072 29.5849 4.16434 23.337 4.16434 20.4272C4.16434 17.5175 3.91672 11.2607 5.02149 8.63655C5.76435 6.88714 7.20244 5.53937 9.07864 4.83425C11.8882 3.78996 18.5549 4.03095 21.6596 4.03095C24.7644 4.03095 31.4406 3.79888 34.2406 4.83425C36.1073 5.53045 37.5454 6.87821 38.2978 8.63655C39.412 11.2696 39.1549 17.5175 39.1549 20.4272C39.1549 23.337 39.412 29.5938 38.2978 32.2179Z"
      fill="url(#paint0_linear_1771_1009)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1771_1009"
        x1="0.30957"
        y1="5.06363"
        x2="43.9491"
        y2="6.27623"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56C573" />
        <stop offset="1" stopColor="#5BC0AC" />
      </linearGradient>
    </defs>
  </svg>
);
