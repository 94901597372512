
const idToModifier = {
  '001': 'lg', 
  '002': 'md',
  '003': 'sm',
};
const modifierMultiplier = {
  'lg': 2.0,
  'md': 1.0,
  'sm': 0.5,
};

const calculatePointsBasedOnModifier = (co2PerUnit, numberOfUnits, modifier) => {

  const points = co2PerUnit * numberOfUnits * (modifierMultiplier[modifier] || 1);
  return Math.ceil(points) / 100 * numberOfUnits;
};

const calculatePoints = (id, co2PerUnit, numberOfUnits) => {
  const modifier = idToModifier[id] || 'md'; 
  return calculatePointsBasedOnModifier(co2PerUnit, numberOfUnits, modifier);
};

export { calculatePoints };