import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { getQuizPoolForUser } from '../graphql/queries';
import { useGetCurrentUserProfile } from './use-get-current-user-profile.hook';

const formatQuizObject = (quiz = undefined) => ({
  actionId: quiz?.action_id || null,
  answer: quiz?.correct_answers || null,
  fact: quiz?.fact_text || '',
  id: quiz?.quiz_id || null,
  options: quiz?.answers ? quiz?.answers.split('\n') : [],
  question: quiz?.question_text || '',
});

const filterInvalidQuiz = (quiz = undefined) =>
  quiz &&
  quiz?.id !== null &&
  quiz?.answer !== null &&
  quiz?.options.length > 0;

export const useGetActionQuiz = () => {
  const { actionId } = useParams();
  const { userProfile } = useGetCurrentUserProfile();
  const { actionQuiz, setActionQuiz } = useOutletContext();

  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    const fetchActionQuiz = async () => {
      setLoading(true);

      try {
        const response = await API.graphql({
          query: getQuizPoolForUser,
          variables: {
            user_id: userProfile?.id,
            action_id: actionId,
          },
        });

        const quizPool = await response.data.getQuizPoolForUser
          .map(formatQuizObject)
          .filter(filterInvalidQuiz);

        if (quizPool.length === 0) {
          setIsEmpty(true);
          setLoading(false);
          return;
        }

        const randomQuizKey = Math.floor(Math.random() * quizPool.length);
        const selectedQuiz = quizPool[randomQuizKey] || quizPool[0];

        setActionQuiz(selectedQuiz);
        setIsEmpty(false);
        setLoading(false);
      } catch (e) {
        // This error state will need to be rendered in the UI!
        console.log('Error: ', e);
        setLoading(false);
      }
    };

    if (actionId && userProfile?.id && !loading && !actionQuiz && !isEmpty) {
      fetchActionQuiz();
    }
  }, [actionId, userProfile, loading, actionQuiz, isEmpty, setActionQuiz]);

  return {
    actionQuiz,
    isEmpty,
    loading,
  };
};
