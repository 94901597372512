import * as React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { BaseComponent } from '../../../prop-types/component';
import { useCreateUser } from '../../../hooks/use-create-user.hook';
import useTranslation from '../../../components/customHooks/translations';
import { SIGNUP_NAMES } from '../../../constants/signup-paths';
import { PAGE_PATHS } from '../../../constants/page-paths';
import TextInput from '../../../components/Inputs/TextInput';
import { LoadingSpinner } from '../LoadingSpinner';
import { ResponseError } from '../ResponseError';

export const initialSignupData = {
  email: '',
  password: '',
  preferred_username: '',
  confirm_password: '',
  confirmation_code: '',
};

const isEmptyString = (str) => str.trim() === '';

export const InfoPrimary = () => {
  const translation = useTranslation();
  const navigate = useNavigate();

  const [signupData, setSignupData] = React.useState(initialSignupData);
  const [signupErrors, setSignupErrors] = React.useState({});

  const { createUser, isLoading, error, success } = useCreateUser();
  const [, setLoginInfo] = useOutletContext();

  React.useEffect(() => {
    const curError = error;
    if (curError) {
      setSignupErrors((prev) => {
        return {
          ...prev,
          default: curError,
        };
      });
    }
  }, [error]);

  function handleChange(e) {
    e.persist();
    setSignupData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  function validateSignup() {
    let tempErrors = {};
    //email errors
    if (isEmptyString(signupData.email)) {
      tempErrors.email = translation.emailRequired;
    } else {
      //Regex found at https://uibakery.io/regex-library/email
      const emailRegex = /^\S+@\S+\.\S+$/;
      if (!emailRegex.test(signupData.email)) {
        tempErrors.email = translation.invalidEmail;
      }
    }
    //username errors
    if (isEmptyString(signupData.preferred_username)) {
      tempErrors.preferred_username = translation.usernameRequired;
    }
    //password errors
    if (isEmptyString(signupData.password)) {
      tempErrors.password = translation.passwordRequired;
    } else {
      //At least one uppercase letter
      //At least one lowercase letter
      //At least one digit
      //At least one special character
      //Should be more than 8 characters
      //Should be less than 16 characters
      let passwordErrors = [];
      if (signupData.password.length < 8) {
        passwordErrors.push(translation.more8Characters);
      }
      if (signupData.password.length > 16) {
        passwordErrors.push(translation.less16Characters);
      }
      if (!/[A-Z]/.test(signupData.password)) {
        passwordErrors.push(translation.oneUppercase);
      }
      if (!/[a-z]/.test(signupData.password)) {
        passwordErrors.push(translation.oneLowercase);
      }
      if (!/\d/.test(signupData.password)) {
        passwordErrors.push(translation.oneDigit);
      }
      if (!/\W/.test(signupData.password)) {
        passwordErrors.push(translation.oneSpecialCharacter);
      }
      if (passwordErrors.length > 0) {
        tempErrors.password = passwordErrors.join('. ');
      }
    }
    //confirm password errors
    if (signupData.password !== signupData.confirm_password) {
      tempErrors.confirm_password = translation.passwordNotMatch;
    }
    setSignupErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  }

  const onSubmitForm = (event) => {
    event.preventDefault();
    if (!validateSignup()) return;

    setLoginInfo({ email: signupData.email, password: signupData.password });
    createUser(signupData);
  };

  React.useEffect(() => {
    if (!isLoading && success) {
      navigate(PAGE_PATHS.CONFIRM);
    }
  }, [isLoading, success, navigate]);

  return (
    <Box
      data-testid="info-primary"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1em',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box>
        <Box
          component="img"
          alt=""
          src="../../assets/images/avatars/LetsGoWhale.svg"
          mb="1em"
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '1em',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Typography variant="h1">
          {translation['signup'].infoPrimaryHeadingOne}
        </Typography>

        <Typography variant="p">
          {translation['signup'].infoPrimarySubHeading}
        </Typography>
        <ResponseError error={error} />
        <Box
          id={`form-${SIGNUP_NAMES.INFO_PRIMARY}`}
          component="form"
          onSubmit={onSubmitForm}
          sx={{
            maxWidth: '25rem',
            width: '100%',
            '& > .MuiBox-root': { margin: '1em 0' },
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
          }}
        >
          <TextInput
            fullWidth
            id="preferred_username"
            name="preferred_username"
            label={translation.username}
            inputType="text"
            variant="outlined"
            required
            value={signupData.preferred_username}
            onChange={handleChange}
            error={!!signupErrors?.preferred_username}
            helperText={signupErrors?.preferred_username}
            disabled={isLoading}
            placeholder={translation.enterYourUsername}
          />

          <TextInput
            id="email"
            name="email"
            label={translation.email}
            inputType="email"
            variant="outlined"
            required
            value={signupData.email}
            onChange={handleChange}
            error={!!signupErrors?.email}
            helperText={signupErrors?.email}
            disabled={isLoading}
            placeholder={translation.enterYourEmail}
            fullWidth
          />

          <TextInput
            fullWidth
            required
            label={translation.password}
            name="password"
            id="password"
            placeholder={translation.enterYourPassword}
            autoComplete="new-password"
            value={signupData.password}
            onChange={handleChange}
            error={!!signupErrors?.password}
            helperText={signupErrors?.password}
            disabled={isLoading}
            inputType="password"
          />

          <TextInput
            fullWidth
            required
            autoComplete="new-password"
            label={translation.confirmPassword}
            placeholder={translation.enterYourPassword}
            name="confirm_password"
            id="confirm_password"
            value={signupData.confirm_password}
            onChange={handleChange}
            error={!!signupErrors?.confirm_password}
            helperText={signupErrors?.confirm_password}
            disabled={isLoading}
            inputType="password"
          />
          <LoadingSpinner loading={isLoading} sx={{ marginBottom: '1rem'}}>
            {translation.creatingYourAccount}
          </LoadingSpinner>
          {!!signupErrors.default && (
            <Box>
              <Typography variant="p" component="p">
                {signupErrors.default.toString()}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

InfoPrimary.propTypes = {
  ...BaseComponent,
};
