import { useParams } from 'react-router-dom';
import { useGetAllActions } from './use-get-all-actions';
import { useCallback } from 'react';

export const allActionStyles = {
  0: { color: '#ffffff' },
  1: { color: '#8EDAAC' },
  2: { color: '#E661AE' },
  5: { color: '#8CBD5B' },
  3: { color: '#6FDDDD' },
  11: { color: '#F1A660' },
  12: { color: '#FFD467' },
  13: { color: '#cf8bff' },
};

export const useGetSingleAction = () => {
  const { actionId } = useParams();
  const { allActions } = useGetAllActions();

  const fetchSingleAction = useCallback(
    () =>
      actionId && allActions.length > 0
        ? allActions.find(({ id }) => id.toString() === actionId)
        : null,
    [actionId, allActions]
  );

  const action = fetchSingleAction();
  const actionStyles = allActionStyles[actionId] || allActionStyles[0];

  return {
    action,
    actionStyles,
  };
};
