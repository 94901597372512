import React from 'react';

import { Grid, Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import LanguageHandler from '../LanguageHandler';
import useTranslation from '../customHooks/translations';
// const icon192x192 = require('/public/icon-192x192.png');


const AppBar = styled(Grid)(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  backgroundColor: '#262a2c',
  zIndex: '100',
  top: '0px',
  width: '100%',
  justifyContent: 'space-between',
  padding: '10px 5px',
  '& label': {
    color: '#fff',
    fontSize: '18px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.1em',
  fontWeight: '700',
  color: '#CCE8CD',
  display: 'inline-block'
}));

function LoginNavbar(props) {
  const translation = useTranslation();

  return (
    <AppBar item xs={12} alignItems="center">
      <Box>
        <Box
          component="img"
          sx={{
            height: 36,
            width: 36,
            marginRight: 1,
          }}
          alt=""
          src={`${process.env.PUBLIC_URL}/icon-192x192.png`}
        />

        <Title
          variant="h6"
          component={'span'}
          noWrap
        >
          Commit2Act
        </Title>
      </Box>  
      <div>
        <LanguageHandler />
      </div>
    </AppBar>
  );
}

export default LoginNavbar;
