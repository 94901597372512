import * as React from 'react';
import { PropTypes } from 'prop-types';
import { Navigate } from 'react-router-dom';
import { PAGE_PATHS } from '../constants/page-paths';

export const AdminRoute = ({
  children,
  isUserAdmin = false,
  redirectTo = PAGE_PATHS.DASHBOARD,
}) => {
  if (!isUserAdmin) return <Navigate to={redirectTo} />;
  return children;
};

AdminRoute.propTypes = {
  children: PropTypes.node,
  isUserAdmin: PropTypes.bool,
  redirectTo: PropTypes.string,
};
