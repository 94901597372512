import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const ResponseError = ({ error = null }) => {
  const hasError = error !== null;
  return (
    <Box aria-live="assertive" sx={hasError ? {} : visuallyHidden}>
      {hasError ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="darkred"
          gap="0.35em"
        >
          <ErrorOutlineIcon fontSize="1em" />
          <Typography
            variant="p"
            fontWeight="bold"
            sx={{ color: 'inherit !important' }}
          >
            {error}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

ResponseError.propTypes = {
  error: PropTypes.string,
};
