import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { getSimpleUserFromAttributes } from '../utils/get-simple-user-from-attributes';

export const useGetCurrentUserProfile = () => {
  const [userProfile, setUserProfile] = useState(undefined);

  useEffect(() => {
    const getCurrentUserProfile = async () => {
      if (!Auth.Credentials.Auth) return;

      try {
        const { attributes } = await Auth.currentAuthenticatedUser();
        const currentUserObject = getSimpleUserFromAttributes(attributes);

        setUserProfile(currentUserObject);
      } catch {
        setUserProfile(undefined);
      }
    };

    if (userProfile === undefined) {
      getCurrentUserProfile();
    }
  }, [userProfile]);

  return { userProfile };
};
