import { PAGE_PATHS } from './page-paths';

export const LOG_ACTION_BASE_PATH = PAGE_PATHS.LOG_ACTION;
export const LOG_ACTION_TYPE_PATH = `${LOG_ACTION_BASE_PATH}/:actionId`;
export const LOG_ACTION_SAVED_PATH = `${LOG_ACTION_TYPE_PATH}/:userActionId`;

export const LOG_ACTION_PATHS = {
  SELECT_TYPE: LOG_ACTION_BASE_PATH,
  ENTER_DETAILS: LOG_ACTION_TYPE_PATH,
  CREATE_ACTION: `${LOG_ACTION_TYPE_PATH}/create`,
  ACTION_SAVED: LOG_ACTION_SAVED_PATH,
  BONUS_QUIZ: `${LOG_ACTION_SAVED_PATH}/bonus`,
  SHARE_SOCIAL: `${LOG_ACTION_SAVED_PATH}/share`,
};

export const getLogActionPath = (
  pathName,
  actionId = null,
  userActionId = null
) => {
  const actionIdPathName =
    LOG_ACTION_PATHS[pathName].replace(':actionId', actionId) ||
    LOG_ACTION_BASE_PATH;
  if (!userActionId) return actionIdPathName;
  return actionIdPathName.replace(':userActionId', userActionId);
};
