import React, { useEffect } from 'react';
import { useUpdateCurrentUserProfile } from './use-update-current-user-profile.hook';
import { useGetCurrentUserProfile } from './use-get-current-user-profile.hook';
import { intendUseOptions } from '../constants/intend-use-options';

export const defaultIntendUse = [];

export const useSignupIntendUse = () => {
  const { userProfile } = useGetCurrentUserProfile();
  const { updateUserProfile } = useUpdateCurrentUserProfile();
  const [intendUse, setIntendUse] = React.useState(defaultIntendUse);
  
  useEffect(() => {
    if (
      userProfile !== undefined &&
      userProfile['custom:intendUse'] !== defaultIntendUse
    ) {
      setIntendUse(userProfile['custom:intendUse']?.split(','));
    }
  }, [userProfile]);

  const sendIntendUseRequest = async () => await updateUserProfile({'custom:intendUse': intendUse?.toString() || ''});

  return {
    intendUseOptions,
    intendUse,
    setIntendUse,
    sendIntendUseRequest
  };
};