import React, { useEffect } from 'react';
import { useSelectCountry } from './use-select-country.hook';
import { useUpdateCurrentUserProfile } from './use-update-current-user-profile.hook';
import { useGetCurrentUserProfile } from './use-get-current-user-profile.hook';

export const defaultValues = {
  nickname: '',
  age: '',
  country: 'CA',
  province: '',
};

export const ageRanges = [
  { value: '<15', label: '14 years and under' },
  { value: '15-24', label: '15 to 24 years' },
  { value: '25-44', label: '25 to 44 years' },
  { value: '45-64', label: '45 to 64 years' },
  { value: '>64', label: '65 years and over' },
];

export const useSignupInfoSecondary = () => {
  const { userProfile } = useGetCurrentUserProfile();
  const { countries, getProvincesForCountry } = useSelectCountry();
  const { updateUserProfile } = useUpdateCurrentUserProfile();
  const [values, setValues] = React.useState(defaultValues);

  const provinces = getProvincesForCountry(values.country);

  // Temporary stand-in for API request method
  const sendValuesRequest = async () => await updateUserProfile(
    {
      'preferred_username': values.nickname,
      'custom:ageRange': values.age,
      'custom:country': values.country,
      'custom:province': values.province,
    });

  useEffect(() => {
    if (
      userProfile !== undefined
    ) {
      const userValues = {
        nickname: userProfile['preferred_username'] || '',
        age: userProfile['custom:ageRange'] || '',
        country: userProfile['custom:country'] || '',
        province: userProfile['custom:province'] || '',
      };
      if(userValues !== defaultValues) {
        setValues(userValues);
      }
    }
  }, [userProfile]);

  return {
    ageRanges,
    countries,
    provinces,
    values,
    setValues,
    sendValuesRequest,
  };
};
