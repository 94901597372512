import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PAGE_PATHS } from '../constants/page-paths';
import Login from '../components/authentication/Login_material';

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path={PAGE_PATHS.LOGIN}
        element={
          <Login
            logo="custom"
            type="image"
            themeColor="standard"
            animateTitle={false}
            title="Commit2Act"
            darkMode
            disableSignUp={false}
          />
        }
      />
    </Routes>
  );
};
