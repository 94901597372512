import React from 'react';
import PropTypes from 'prop-types';
import {
  LinearProgress as Progress,
  linearProgressClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { BaseComponent } from '../../prop-types/component';

export const LinearProgress = ({ name, value, className, sx }) => {
  const StyledLinearProgress = styled(Progress)(({ theme }) => ({
    height: 18,
    borderRadius: 25,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background: 'linear-gradient(90deg, #26AAB7 0%, #8EDAAC 100%)',
    },
  }));

  return (
    <StyledLinearProgress
      variant="determinate"
      value={value}
      aria-label={name}
      className={className}
      sx={{
        backgroundColor: '#262a2c',
        border: '1px solid',
        borderColor: '#262a2c',
        ...sx,
      }}
    />
  );
};

LinearProgress.propTypes = {
  ...BaseComponent,
  value: PropTypes.number,
  name: PropTypes.string,
};
