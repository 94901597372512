import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { BaseComponent } from '../../../prop-types/component';
import useTranslation from '../../../components/customHooks/translations';
import { SIGNUP_NAMES, SIGNUP_PATHS } from '../../../constants/signup-paths';
import { useSignupPreferActions } from '../../../hooks/use-signup-prefer-actions.hook';
import { MultiSelectTileGroup } from '../../../components/MultiSelectTile/MultiSelectTileGroup';
import { LoadingSpinner } from '../LoadingSpinner';

export const ActionsSelect = () => {
  const { signup } = useTranslation();
  const navigate = useNavigate();
  const { actionOptions, setPreferActions, sendPreferActionsRequest } =
    useSignupPreferActions();
  const [loading, setLoading] = React.useState(false);

  const [selectedItems, setSelectedItems] = React.useState([]);
  React.useEffect(() => {
    setPreferActions(selectedItems);
  }, [selectedItems, setPreferActions]);

  const onSubmitForm = async (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);

      try {
        await sendPreferActionsRequest();
        setLoading(false);
        navigate(SIGNUP_PATHS.ACTIONS_IMPACT);
      } catch (e) {
        setLoading(false);
        console.log('Error: ', e);
      }
    }
  };
  return (
    <Box data-testid="actions-select">
      <Typography variant="h1">{signup.actionsSelectHeading}</Typography>
      <Typography
        variant="p"
        component="p"
        sx={{
          lineHeight: '1.5',
          maxWidth: '600px',
          margin: '0 auto',
          paddingTop: '1.25em',
        }}
      >
        {signup.actionsSelectSubheading}
      </Typography>
      <Box
        id={`form-${SIGNUP_NAMES.ACTIONS_SELECT}`}
        component="form"
        onSubmit={onSubmitForm}
      >
        <MultiSelectTileGroup
          selectionOptions={actionOptions}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          ariaLabel="prefer-actions-select-label"
          name="prefer-actions"
        />
      </Box>

      <LoadingSpinner loading={loading} />
    </Box>
  );
};

ActionsSelect.propTypes = {
  ...BaseComponent,
};
