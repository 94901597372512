export const getSimpleActionItemFromResponse = (responseActionItem, idx) => {
  const actionId = responseActionItem?.action_id || null;
  return {
    actionId,
    id: `${actionId}-${idx}`,
    name: responseActionItem?.item_name || '',
    description: responseActionItem?.item_description || '',
    co2Saved: responseActionItem?.co2_saved_per_unit || 0,
  };
};
