import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, MenuItem, Typography } from '@mui/material';
import useTranslation from '../../../components/customHooks/translations';
import { useSignupInfoSecondary } from '../../../hooks/use-signup-info-secondary.hook';
import { SIGNUP_NAMES, SIGNUP_PATHS } from '../../../constants/signup-paths';
import TextInput from '../../../components/Inputs/TextInput';
import Select from '../../../components/Inputs/Select';
import { LoadingSpinner } from '../LoadingSpinner';

export const InfoSecondary = () => {
  const { signup } = useTranslation();

  const navigate = useNavigate();
  const {
    ageRanges,
    countries,
    provinces,
    values,
    setValues,
    sendValuesRequest,
  } = useSignupInfoSecondary();
  const [loading, setLoading] = React.useState(false);

  const onSubmitForm = async (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);

      try {
        await sendValuesRequest();
        setLoading(false);
        navigate(SIGNUP_PATHS.ONBOARD_START);
      } catch (e) {
        setLoading(false);
        console.log('Error: ', e);
      }
    }
  };

  return (
    <Box data-testid="info-secondary">
      <Box>
        <Box
          component="img"
          alt=""
          src="../../assets/images/avatars/LetsGoWhale.svg"
          mb="1em"
        />
      </Box>
      <Typography variant="h1">{signup.infoSecondaryHeading}</Typography>
      <Typography variant="p" component="p" mt="1em">
        {signup.infoSecondarySubHeading}
      </Typography>

      {/* Consider everything within this form Box as temporary placeholders! */}
      <Box
        id={`form-${SIGNUP_NAMES.INFO_SECONDARY}`}
        component="form"
        onSubmit={onSubmitForm}
        sx={{
          maxWidth: '25rem',
          margin: '2em auto',
          '& > .MuiBox-root': { margin: '1em 0' },
        }}
      >
        <Box>
          <TextInput
            id="nickname"
            label={signup.nickname}
            placeholder={signup.enterYourNickname}
            value={values.nickname}
            disabled={loading}
            onChange={(event) =>
              setValues({ ...values, nickname: event.target.value })
            }
            fullWidth
            inputType="text"
          />
        </Box>
        <Box>
          <Select
            id="age"
            label={signup.ageRange}
            value={values.age}
            disabled={loading}
            onChange={(event) =>
              setValues({ ...values, age: event.target.value })
            }
            fullWidth
            select
          >
            {ageRanges.map((ageRange) => (
              <MenuItem key={ageRange.label} value={ageRange.value}>
                {ageRange.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <Select
            id="country"
            label={signup.country}
            value={values.country}
            disabled={loading}
            onChange={(event) =>
              setValues({ ...values, country: event.target.value })
            }
            fullWidth
            select
          >
            {countries.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {provinces.length > 0 ? (
          <Box>
            <Select
              id="province"
              label={signup.province}
              value={values.province}
              disabled={loading}
              onChange={(event) =>
                setValues({ ...values, province: event.target.value })
              }
              fullWidth
              select
            >
              {provinces.map((province) => (
                <MenuItem key={province.code} value={province.code}>
                  {province.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : null}
        <LoadingSpinner loading={loading} sx={{marginBottom: '1rem'}}/>
      </Box>
    </Box>
  );
};
