import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Line } from "react-chartjs-2";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { useDashboardData } from './DashboardProvider';
import useTranslation from '../customHooks/translations';

const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
const ImpactBox = () => {
  const { impactObject:impact } = useDashboardData();
  const dateEnd = new Date().getMonth();

  let dateStart = dateEnd - 4;
  if(dateStart < 0) dateStart = 12 + dateStart;

  const graphData = [];
  const graphLabels = [];
  for (let i = 0; i < 5; i++) { 
    let index = (dateStart + i) % 12; 
    graphLabels.push(months[index]);
    graphData.push(impact?.actions_by_month[index])
  }

  const theme = useTheme();
  const translation = useTranslation();

  /** functions for formatNumber */
  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + ' tonnes';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(0) + ' kg';
    } else {
      return num.toFixed(0) + ' g';
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 3,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: { size: 14 },
          color: '#CCE8CD', 
        }
      },
      title: {
        padding: 20,
        display: true,
        align: 'start',
        text: 'Total CO2 in grams',
        font: { size: 18, weight: '600' },
        color: theme.palette.common.light, 
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'rgb(204, 232, 205, 1)',
        },
        grid: {
          color: 'rgb(204, 232, 205, 0)',        
        }
      },
      y: {
        ticks: {
          color: 'rgb(204, 232, 205, 1)',
        },
        grid: {
          color: 'rgb(204, 232, 205, 0.15)',
        }
      }
    }
  };
  
  
  const data = {
    labels: graphLabels,
    datasets: [
      {
        label: "My progress",
        data: graphData,
        fill: false,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      },
    ],
  };

  return (
    <Box>
      <Paper sx={{
        borderRadius: '20px',
        background: 'linear-gradient(180deg, #00E8FF 0%, #26AAB7 78.82%)',
        padding: '2em 1em',
        textAlign: 'center',
        color: theme.palette.common.dark,
        lineHeight: '1.2em',
        mb: '2em',
      }}>
      <Typography sx={{fontWeight: '900', paddingBottom: '15px', fontSize: '1.67em', color: theme.palette.common.dark}}>{ translation.formatString(translation['dashboardPage'].co2Saved, formatNumber(impact.weekly_co2))}</Typography>
        <Typography variant="body"> 
        {translation['dashboardPage'].thisWeek}< br/>
        <TrendingUpIcon sx={{color: theme.palette.secondary.main, marginBottom: '-5px' }}/> <strong>+20%</strong> {translation['dashboardPage'].fromLastWeek}
        </Typography>
      </Paper>
      <div style={{ height: '500px' }}>
        <Line options={options} data={data} aria-label="Line chart showing the impact of your actions over time"/>
      </div>
    </Box>
  )
};

export default ImpactBox;