import * as React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { PAGE_PATHS } from '../constants/page-paths';
import {
  ONBOARD_PATH,
  PRIVATE_SIGNUP_ROUTES,
  SIGNUP_PATHS,
} from '../constants/signup-paths';
import { LOG_ACTION_ROUTES } from '../constants/log-action-routes';
import PageContainer from '../views/pageContainer/PageContainer';
import ScrollToTop from '../components/ScrollToTop';
import Login from '../components/authentication/Login_material';
import LogAction from '../pages/LogAction';
import Actions from '../pages/Actions';
import Landing from '../pages/Landing';
import MyGroups from '../pages/MyGroups';
import FindGroup from '../pages/FindGroup';
import CreateGroup from '../pages/CreateGroup';
import GroupProfile from '../pages/GroupProfile';
import ValidateActions from '../pages/ValidateActions';
import AccountSettings from '../pages/AccountSettings';
import UserProfile from '../pages/UserProfile';
import AdminDashboard from '../pages/AdminDashboard';
import JoinGroup from '../pages/JoinGroup';
import CreateAction from '../pages/CreateAction';
import { SignupContainer } from '../views/SignupContainer/SignupContainer';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { AdminRoute } from './AdminRoute';
import { useGetCurrentUser } from '../hooks/use-get-current-user.hook';
import * as signupPages from '../pages/Signup';
import * as logActionPages from '../pages/LogActionPages';
import { isSimpleLogActionSteps } from '../utils/log-action-steps-type';
import { isSimpleDashboardVersion } from '../utils/dashboard-version';
import { UserDashboard } from '../pages/UserDashboard/UserDashboard';
import { LogActionRoutes } from './LogActionRoutes';

const defaultBaseRoute = '/';
const { InfoPrimary, UserConfirm } = signupPages;

const privateOnboardRoutes = PRIVATE_SIGNUP_ROUTES.map(({ path, name }) => {
  const OnboardPage = signupPages[name];
  return {
    element: <OnboardPage />,
    path,
  };
});

const logActionRoutes = isSimpleLogActionSteps()
  ? [
      { path: PAGE_PATHS.LOG_ACTION, element: <LogAction /> },
      { path: PAGE_PATHS.LOG_ACTION_ADD_ACTION, element: <LogAction /> },
    ]
  : LOG_ACTION_ROUTES.map(({ path, name }) => {
      const LogActionPage = logActionPages[name];
      return {
        element: <LogActionPage />,
        path,
      };
    });

export const Routing = () => {
  const { isUserLoggedIn, isUserAdmin } = useGetCurrentUser();

  const routing = useRoutes([
    {
      path: PAGE_PATHS.LOGIN,
      element: (
        <PublicRoute isUserLoggedIn={isUserLoggedIn}>
          <Login
            animateTitle={false}
            darkMode
            logo="custom"
            themeColor="standard"
            title="Commit2Act"
            type="image"
          />
        </PublicRoute>
      ),
    },
    {
      path: PAGE_PATHS.SIGNUP,
      element: <SignupContainer />,
      children: [
        { path: PAGE_PATHS.SIGNUP, element: <InfoPrimary /> },
        { path: PAGE_PATHS.CONFIRM, element: <UserConfirm /> },
      ],
    },
    {
      path: ONBOARD_PATH,
      element: (
        <PrivateRoute isUserLoggedIn={isUserLoggedIn}>
          <ScrollToTop />
          <SignupContainer />
        </PrivateRoute>
      ),
      children: [
        ...privateOnboardRoutes,
        {
          path: ONBOARD_PATH,
          element: <Navigate to={SIGNUP_PATHS.INFO_SECONDARY} />,
        },
      ],
    },
    {
      path: defaultBaseRoute,
      element: (
        <PrivateRoute isUserLoggedIn={isUserLoggedIn}>
          <ScrollToTop />
          <PageContainer />
        </PrivateRoute>
      ),
      children: [
        {
          path: PAGE_PATHS.LOG_ACTION,
          element: <LogActionRoutes />,
          children: logActionRoutes,
        },
        { path: PAGE_PATHS.ACTIONS, element: <Actions /> },
        {
          path: PAGE_PATHS.DASHBOARD,
          element: isSimpleDashboardVersion() ? <Landing /> : <UserDashboard />,
        },
        { path: PAGE_PATHS.MY_GROUPS, element: <MyGroups /> },
        { path: PAGE_PATHS.FIND_GROUP, element: <FindGroup /> },
        { path: PAGE_PATHS.CREATE_GROUP, element: <CreateGroup /> },
        { path: PAGE_PATHS.GROUP_PROFILE, element: <GroupProfile /> },
        { path: PAGE_PATHS.VALIDATE_ACTIONS, element: <ValidateActions /> },
        { path: PAGE_PATHS.MY_ACCOUNT, element: <AccountSettings /> },
        { path: PAGE_PATHS.USER_PROFILE, element: <UserProfile /> },
        { path: PAGE_PATHS.ADMIN_DASHBOARD, element: <AdminDashboard /> },
        { path: PAGE_PATHS.GROUP_PROFILE_ADD_USER, element: <JoinGroup /> },
        {
          path: PAGE_PATHS.CREATE_ACTION,
          element: (
            <AdminRoute isUserAdmin={isUserAdmin}>
              <CreateAction />
            </AdminRoute>
          ),
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to={defaultBaseRoute} />,
    },
  ]);
  return routing;
};
