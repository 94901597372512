import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { BaseComponent } from '../../prop-types/component';
import ActionsTile from '../../components/UserDashboard/ActionsTile';
import LearningBox from '../../components/UserDashboard/LearningBox';
import RankingsBox from '../../components/UserDashboard/RankingsBox';
import ImpactBox from '../../components/UserDashboard/ImpactBox';
import StatsBox from '../../components/UserDashboard/StatsBox';
import theme from '../../themes';
import { DashboardDataProvider } from '../../components/UserDashboard/DashboardProvider';
import Greeting from '../../components/UserDashboard/Greeting';
import useTranslation from '../../components/customHooks/translations';

export const DashboardTile = ({ children, sx, ...props }) => (
  <Box
    component="section"
    sx={{
      padding: '1.5rem',
      border: 'solid 0.1rem rgba(255, 255, 255, 0.1)', //temporary
      borderRadius: '0.75rem',
      '& > h2': {
        marginBottom: '0.5em',
        fontWeight: 'bold',
      },
      '& > div': {
        color: 'white',
        fontSize: '1rem',
      },
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
);

DashboardTile.propTypes = {
  ...BaseComponent,
};

export const UserDashboard = () => {
  const {dashboardPage: translation} = useTranslation();
  return (
    <DashboardDataProvider>
      <Box data-testid="user-dashboard" sx={{ mb: '5rem' }}>
        <Box component="header" sx={{ marginBottom: '2.5rem' }}>
          <Greeting />
        </Box>

        <Box
          sx={{
            gap: '1rem',
            display: 'grid',
            gridTemplateColumns: '3fr 1fr',
            gridTemplateRows: 'auto',
            gridTemplateAreas: `"stats learning"
              "actions rankings"
              "actions impact"`,
            [theme.breakpoints.down('lg')]: {
              gridTemplateColumns: '1fr 1fr',
            },
            [theme.breakpoints.down('md')]: {
              display: 'block',
              '& > section': {
                margin: '1rem 0',
              },
            },
          }}
        >
          <DashboardTile gridArea="stats" data-testid="tile-stats">
            <Typography variant="h2">{translation.statProgress}</Typography>
            <StatsBox />
          </DashboardTile>

          <DashboardTile gridArea="actions" data-testid="tile-actions">
            <Typography variant="h2">{translation.yourActions}</Typography>
            <ActionsTile />
          </DashboardTile>
          <DashboardTile
            gridArea="learning"
            data-testid="tile-learning"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h2">
              {translation.growYourKnowledge}
            </Typography>
            <LearningBox />
          </DashboardTile>
          <DashboardTile gridArea="rankings" data-testid="tile-rankings">
            <Typography variant="h2">{translation.leaderboard}</Typography>
            <RankingsBox />
          </DashboardTile>
          <DashboardTile gridArea="impact" data-testid="tile-impact">
            <Typography variant="h2">{translation.yourImpact}</Typography>
            <ImpactBox />
          </DashboardTile>
        </Box>
      </Box>
    </DashboardDataProvider>
  );
};
