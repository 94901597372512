import * as React from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { getLogActionPath } from '../../../constants/log-action-paths';
import { useGetAllActions } from '../../../hooks/use-get-all-actions';
import useTranslation from '../../../components/customHooks/translations';
import { LogActionContainer } from '../../../views/LogActionContainer/LogActionContainer';
import { useTheme } from '@mui/material/styles';
import { useAvatar } from '../../../hooks/use-get-avatar.hook';

const actionColors = [
  {
    id: 1,
    color: '#B0F36D',
  },
  {
    id: 2,
    color: '#FF9CD5',
  },
  {
    id: 3,
    color: '#78F1F1',
  },
  {
    id: 5,
    color: '#9EF2BF',
  },
  {
    id: 11,
    color: '#FFAC5E',
  },
  {
    id: 12,
    color: '#FFE871',
  },
  {
    id: 13,
    color: '#cf8bff',
  },
];

export const SelectType = () => {
  const { unsetUserActionValues } = useOutletContext();
  const { logActionPage, logActionStep1 } = useTranslation();
  const { availableActions, loading } = useGetAllActions();
  const theme = useTheme();

  const { avatar } = useAvatar();
  const getActionIcon = (id) => {
    const actionObject = avatar.actions.find((action) => action.id === id);
    return actionObject?.icon ? actionObject.icon : avatar.imageUrl;
  };

  const getActionColor = (id) => {
    const actionColor = actionColors.find((color) => color.id === id);
    if (actionColor?.color) return actionColor.color;
    return typeof id === 'number'
      ? actionColors[id % actionColors.length].color
      : actionColors[0].color;
  };

  // Ensure all stored values for previous action is cleared
  React.useLayoutEffect(() => {
    unsetUserActionValues();
  });

  return (
    <LogActionContainer>
      <Box
        sx={{
          textAlign: 'center',
          [theme.breakpoints.down('md')]: {
            paddingBottom: '6rem',
          },
        }}
      >
        <Typography variant="h1">{logActionStep1}</Typography>
        {loading && (
          <CircularProgress aria-label={logActionPage.loadingActions} />
        )}
        {!loading && availableActions.length === 0 ? (
          <Typography variant="p" component="p">
            {logActionPage.noActionsAvailable}
          </Typography>
        ) : null}

        {!loading && availableActions.length > 0 ? (
          <Box
            sx={{
              display: 'grid',
              alignItems: 'stretch',
              justifyContent: 'center',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '1rem',
              margin: '6rem auto 2rem',
              maxWidth: '62rem',
              [theme.breakpoints.down('md')]: {
                gridTemplateColumns: 'repeat(2, 1fr)',
                maxWidth: '38rem',
                marginTop: '2rem',
              },
              [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: 'repeat(1, 1fr)',
                maxWidth: '100%',
              },
            }}
          >
            {availableActions.map((action) => (
              <Box
                key={action.id}
                component={Link}
                to={getLogActionPath('ENTER_DETAILS', action.id)}
                sx={{
                  color: '#222',
                  borderRadius: '0.5em',
                  padding: '1.5rem 0.25rem 2rem',
                  textDecoration: 'none',
                  background: getActionColor(action.id),
                  border: `solid 0.1em ${getActionColor(action.id)}`,
                  transition: 'border 0.15s, background 0.15s, color 0.15s',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  '&:hover, &:focus': {
                    borderColor: 'white',
                    background: '#23242a',
                    color: 'white',
                  },
                  '&:hover span, &:focus span': {
                    color: 'white',
                  },
                }}
              >
                {action.icon && (
                  <Box
                    alt=""
                    component="img"
                    src={getActionIcon(action.id)}
                    sx={{
                      maxHeight: '10rem',
                      maxWidth: '12rem',
                    }}
                  />
                )}
                <Typography
                  variant="p"
                  sx={{
                    fontSize: '1.15rem',
                    fontWeight: '500',
                    display: 'block',
                    color: theme.palette.common.dark,
                    mt: '0.5em',
                  }}
                >
                  {action.name}
                </Typography>
              </Box>
            ))}
          </Box>
        ) : null}
      </Box>
    </LogActionContainer>
  );
};
