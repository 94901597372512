import React, { useEffect } from 'react';
import { actionOptions } from '../constants/action-options';
import { useUpdateCurrentUserProfile } from './use-update-current-user-profile.hook';
import { useGetCurrentUserProfile } from './use-get-current-user-profile.hook';

export const defaultIgnoreActions = [];

export const useSignupIgnoreActions = () => {
  const { userProfile } = useGetCurrentUserProfile();
  const { updateUserProfile } = useUpdateCurrentUserProfile();
  const [ignoreActions, setIgnoreActions] = React.useState(defaultIgnoreActions);
  
  useEffect(() => {
    if (
      userProfile !== undefined
    ) {
      setIgnoreActions(userProfile['custom:ignoreActions']?.split(','));
    }
  }, [userProfile]);

  const sendIgnoreActionsRequest = async () => await updateUserProfile({'custom:ignoreActions': ignoreActions?.toString() || ''});

  return {
    actionOptions,
    ignoreActions,
    setIgnoreActions,
    sendIgnoreActionsRequest
  };
};