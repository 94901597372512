import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { getSingleSubmittedAction } from '../graphql/queries';
import { getSimpleUserActionFromResponse } from '../utils/get-simple-user-action-from-response';

export const useGetUserAction = () => {
  const { userActionId: userActionIdParam } = useParams();
  const { userActionValues } = useOutletContext();

  const userActionId = userActionValues?.userActionId || userActionIdParam;

  const [loading, setLoading] = React.useState(false);
  const [userAction, setUserAction] = React.useState(null);

  React.useEffect(() => {
    let isMounted = true;

    const getUserAction = async () => {
      
      if (!userActionId) {
        console.log('userActionId is not defined');
        return;
      }
      
      setLoading(true);

      try {
        const res = await API.graphql({
          query: getSingleSubmittedAction,
          variables: { sa_id: userActionId },
        });
        const action = await res.data.getSingleSubmittedAction;
        if (isMounted) { // Check if the component is still mounted
        setUserAction(getSimpleUserActionFromResponse(action));
        setLoading(false);
      }
      } catch (e) {
        console.log('Error: ', e);
        if (isMounted) { // Check if the component is still mounted
          setLoading(false);
        }
      }
    };

    if (!loading && userActionId && !userAction) {
      getUserAction();
    }
  }, [loading, userActionId, userAction]);

  return {
    userAction,
    loading,
  };
};
