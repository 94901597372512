import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { LinearProgress } from '../../components/LinearProgress/LinearProgress';
import { BaseComponent } from '../../prop-types/component';
import { LOG_ACTION_ROUTES } from '../../constants/log-action-routes';
import theme from '../../themes';

const totalSteps = LOG_ACTION_ROUTES.length - 1;

export const LogActionContainer = ({
  children,
  step = 0,
  buttons = null,
  ...props
}) => {
  const progress = Math.ceil((step / totalSteps) * 100);

  return (
    <Box {...props}>
      {step > 0 ? (
        <Box mb="2rem">
          <Box
            sx={{
              width: '100%',
              maxWidth: '36rem',
              margin: '0 auto',
            }}
          >
            <LinearProgress
              value={progress}
              name={`${progress} percent complete`}
              sx={{ backgroundColor: '#eee' }}
            />
          </Box>
        </Box>
      ) : null}
      <Box>{children}</Box>
      {buttons !== null ? (
        <Box
          sx={{
            gap: '2rem',
            display: 'flex',
            flexWrap: 'wrap',
            margin: '4rem 0',
            '& > .MuiButton-root': { fontSize: '1.75rem', minWidth: '8em' },
            [theme.breakpoints.down('md')]: {
              paddingBottom: '2rem',
              justifyContent: 'center',
            },
          }}
        >
          {buttons}
        </Box>
      ) : null}
    </Box>
  );
};

LogActionContainer.propTypes = {
  ...BaseComponent,
  step: PropTypes.number,
  buttons: PropTypes.node,
};
