import { registerLocale, getNames } from 'i18n-iso-countries';
import { useLanguageContext } from '../components/contexts/LanguageContext';
import { PROVINCES } from '../constants/provinces';

registerLocale(require('i18n-iso-countries/langs/en.json'));
registerLocale(require('i18n-iso-countries/langs/fr.json'));
registerLocale(require('i18n-iso-countries/langs/es.json'));

const getNameAndCodeFromEntry = (entry) => ({ code: entry[0], name: entry[1] });

const fallbackLang = 'en';
const canadaCode = 'CA';

export const useSelectCountry = () => {
  const { language } = useLanguageContext();

  const countriesObj = getNames(language) || getNames(fallbackLang);
  const countries = Object.entries(countriesObj).map(getNameAndCodeFromEntry);

  const provincesObj = PROVINCES[language] || PROVINCES[fallbackLang];
  const provinces = Object.entries(provincesObj).map(getNameAndCodeFromEntry);

  const getProvincesForCountry = (countryCode) =>
    countryCode === canadaCode ? provinces : [];

  return {
    countries,
    provinces,
    getProvincesForCountry,
  };
};
