export const getSimpleActionFromResponse = (responseAction) => {
  return {
    id: responseAction?.action_id || null,
    name: responseAction?.action_name || '',
    icon: encodeURI(responseAction?.action_icon) || null,
    pageMedia: responseAction?.page_media || null,
    quizMediaAlt: responseAction?.fallback_quiz_media || '',
    validationLabels: responseAction?.validation_labels || '',
    hidden: !!responseAction?.is_hidden,
  };
};
