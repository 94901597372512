import { useLocation } from 'react-router-dom';
import useTranslation from '../components/customHooks/translations';
import { SIGNUP_ROUTES } from '../constants/signup-paths';

export const useSignupStepProgress = () => {
  const { signup, formatString } = useTranslation();
  const { pathname } = useLocation();

  const route = SIGNUP_ROUTES.find(({ path }) => path === pathname);

  const buttons = route?.buttons;
  const progress = route?.progress || 0;
  const step = route?.step || 0;

  return {
    buttons,
    progressLabel: `${progress}%`,
    progressAriaLabel: formatString(signup.progressLabel, progress),
    stepLabel: formatString(signup.stepLabel, step),
    progress,
    route,
    step,
  };
};
