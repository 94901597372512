const LOG_ACTION_STEPS_TYPE = {
  SIMPLE: 'simple',
  SEPARATE: 'separate',
};

export const getLogActionStepsType = () =>
  process.env.REACT_APP_LOG_ACTION_STEPS || LOG_ACTION_STEPS_TYPE.SIMPLE;

export const isSimpleLogActionSteps = () =>
  getLogActionStepsType() === LOG_ACTION_STEPS_TYPE.SIMPLE;
