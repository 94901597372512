import React, { useEffect } from 'react';
import { actionOptions } from '../constants/action-options';
import { useUpdateCurrentUserProfile } from './use-update-current-user-profile.hook';
import { useGetCurrentUserProfile } from './use-get-current-user-profile.hook';

export const defaultPreferActions = [];

export const useSignupPreferActions = () => {
  const { userProfile } = useGetCurrentUserProfile();
  const { updateUserProfile } = useUpdateCurrentUserProfile();
  const [preferActions, setPreferActions] = React.useState(defaultPreferActions);
  
  useEffect(() => {
    if (
      userProfile !== undefined
    ) {
      setPreferActions(userProfile['custom:preferActions']?.split(','));
    }
  }, [userProfile]);

  const sendPreferActionsRequest = async () => await updateUserProfile({'custom:preferActions': preferActions?.toString() || ''});

  return {
    actionOptions,
    preferActions,
    setPreferActions,
    sendPreferActionsRequest
  };
};