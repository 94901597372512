import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { BaseComponent } from '../../../prop-types/component';
import { SIGNUP_NAMES, SIGNUP_PATHS } from '../../../constants/signup-paths';
import useTranslation from '../../../components/customHooks/translations';
import { useSignupSubjectFamiliar } from '../../../hooks/use-signup-subject-familiar.hook';
import { RadioGroup } from '../../../components/RadioGroup';
import { LoadingSpinner } from '../LoadingSpinner';

export const SubjectFamiliar = () => {
  const handleRadioChange = (value) => {
    setFamiliarity(value);
  };

  const { signup } = useTranslation();
  const navigate = useNavigate();
  const { familiarityOptions, setFamiliarity, sendFamiliarityRequest } =
    useSignupSubjectFamiliar();
  const [loading, setLoading] = React.useState(false);

  const onSubmitForm = async (event) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);

      try {
        await sendFamiliarityRequest();
        setLoading(false);
        navigate(SIGNUP_PATHS.SUBJECT_FACT);
      } catch (e) {
        setLoading(false);
        console.log('Error: ', e);
      }
    }
  };

  return (
    <Box data-testid="subject-familiar">
      <Typography variant="h1" id="subject-familiarity-label">
        {signup.subjectExperiencedHeading}
      </Typography>

      {/* Consider everything within this form Box as temporary placeholders! */}
      <Box
        id={`form-${SIGNUP_NAMES.SUBJECT_FAMILIAR}`}
        component="form"
        onSubmit={onSubmitForm}
        sx={{
          maxWidth: '35rem',
          margin: '2em auto',
          fontSize: '1rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <RadioGroup
          radios={familiarityOptions} // Pass the familiarityOptions array as props
          variant="lightPill" // Set the variant if needed
          onChange={handleRadioChange} // Pass the onChange handler
        />
      </Box>
      <LoadingSpinner loading={loading} />
    </Box>
  );
};

SubjectFamiliar.propTypes = {
  ...BaseComponent,
};
