import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { customFieldOptions } from '../constants/custom-field-options';
import { USER_TYPE } from '../utils/get-simple-user-from-attributes';


export const useCreateUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const createUser = async ({email, password, preferred_username}) => {
    setError(null);
    setIsLoading(true);
    try {
      const attributes = {
        preferred_username,
        name: preferred_username,
        'custom:type': USER_TYPE['MEMBER'],
        'custom:firstLogin': 'true',
        ...customFieldOptions.reduce((acc, {authFieldName}) => {
          acc[authFieldName] = ''
          return acc;
        }, {})
      }
      const { user } = await Auth.signUp({   
        username: email,
        password,
        attributes
      })
      setSuccess(true);
      setIsLoading(false);
      return user;
    } catch (error) {

      console.error('Error signing up:', error);
      setError(error.message || "An unexpected error occurred");

      setIsLoading(false);
      throw error;
    }
  }

  return { createUser, isLoading, error, success}
}

