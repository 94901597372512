import * as React from 'react';
import { SmartToyOutlined } from '@mui/icons-material';

const SmartToyGradient = () => (
  <>
    <svg width={0} height={0}>
      <defs>
        <linearGradient id="gradient">
          <stop offset="0%" stopColor="#33AF99" />
          <stop offset="100%" stopColor="#56C573" />
        </linearGradient>
      </defs>
    </svg>
    <SmartToyOutlined
      sx={{ fill: 'url(#gradient)', fontSize:'6.25rem'}}
    />
  </>
);

export default SmartToyGradient;
